<template>
	<div v-if="comment" class="comment-item" :class="[ comment.approvalState, { light, deleted } ]">
		<div class="comment-user">
			<span v-if="comment.clientUser && comment.clientUser.userName" class="name">{{ comment.clientUser.userName }}</span>
			<span class="date">{{ ago(comment.createdAt) }}</span>
			<span v-if="is_mine" class="dots-button" v-hammer:tap="() => edit_menu_visible = true">...</span>
			<span v-if="!is_mine && is_reviewer" class="dots-button delete" v-hammer:tap="() => { edit_menu_visible = true; remove_visible = true }"></span>
		</div>

		<div class="comment-content">
			<ui-text-content v-if="!editing" :html="comment.body.replace(/\n/g,'<br>')" />
			<form v-else class="form-edit" @submit.prevent="save_edit()">
				<textarea v-model="editing_body" rows="4" class="textarea">{{ comment.body }}</textarea>
				<div class="buttons">
					<div class="button gray" v-hammer:tap="() => editing = false">{{ $t('cancel') }}</div>
					<button type="submit" class="button">{{ $t('submit') }}</button>
				</div>
			</form>
		</div>

		<div v-if="!light" class="comment-footer" :class="{ 'replies-visible': replies_visible }">
			<span class="action reply" v-hammer:tap="() => set_reply()">{{ $t('Reply') }}</span>
			<span class="dot"></span>
			<span class="action like" :class="{ liked: liked }" v-hammer:tap="() => toggle_like()">{{ comment.likeCount }}</span>
			<span class="action show-reply right" :data-nb="replies.length" v-hammer:tap="() => toggle_replies()">{{ replies.length }} {{ replies.length < 2 ? $t('reply') : $t('replies') }}</span>
		</div>
		<div v-if="!light" v-show="replies_visible" class="replies">
			<comment-item v-for="reply in replies" :comment="reply" :key="reply.id" :light="true" :parent="comment" />
		</div>

		<transition name="edit-menu">
			<div v-if="(is_mine || is_reviewer) && edit_menu_visible" class="edit-menu" :class="{ remove: remove_visible }">
				<div v-if="remove_visible" class="remove-label">{{ $t('delete') }} ?</div>
				<div class="actions">
					<div class="action top">
						<div v-if="!remove_visible" class="action-content delete" v-hammer:tap="() => remove_visible = true" :title="$t('delete_comment')"></div>
						<div v-if="remove_visible" class="action-content text capitalize" v-hammer:tap="() => delete_comment()">{{ $t('yes') }}</div>
					</div>
					<div class="action bottom">
						<div v-if="!remove_visible" class="action-content edit" v-hammer:tap="() => show_editing()" :title="$t('edit_comment')"></div>
						<div v-if="remove_visible" class="action-content text capitalize" v-hammer:tap="() => close()">{{ $t('no') }}</div>
					</div>
					<div class="close" v-hammer:tap="() => close()"></div>
				</div>
			</div>
		</transition>

	</div>
</template>

<script>
import Apollo from '@/apollo/apollo-client'
import { mapGetters } from 'vuex'
import { formatDistanceToNow } from 'date-fns'
import UiTextContent from '@/components/ui/ui-text-content'
import CommentItem from '@/components/game/game-result/pages/comment-item'
import { frCA, enUS } from 'date-fns/locale'
import { LANGUAGE } from '@/js/factory/language'

export default {
	name: 'comment-item',
	data() {
		return {
			editing: false,
			editing_body: null,
			edit_menu_visible: false,
			remove_visible: false,
			replies_visible: false,
			deleted: false
		}
	},
	props: {
		comment: { default: false },
		light: { default: false },
		parent: { default: null }
	},
	computed: {
		...mapGetters([ 'app', 'logged_user' ]),
		replies() {
			if (this.comment && this.comment.comments && this.comment.comments.data)
				return this.comment.comments.data
			return []
		},
		liked() {
			return this.comment.clientUsersWhoLiked.findIndex((u) => { return u.id == this.logged_user.id }) > -1
		},
		is_mine() {
			return this.comment.clientUser && this.comment.clientUser.id === this.logged_user.id
		},
		is_reviewer() {
			return !this.is_mine && this.logged_user.reviewer === true
		}
	},
	methods: {
		ago(date) {
			const locale = LANGUAGE.getData({ definition: 'Language' }) === 'fr' ? frCA : enUS
			return formatDistanceToNow(new Date(date), { addSuffix: true, locale })
		},
		toggle_replies() {
			if (this.replies.length > 0) {
				this.replies_visible = !this.replies_visible
			}
		},
		async toggle_like() {
			if (!this.app.loading) {
				let comment = await this.$store.dispatch('toggle_like', { element_type: this.comment.elementType, element_id: this.comment.id })
				if (comment) this.$store.commit('EDIT_COMMENT', { comment: this.comment, likeCount: comment.likeCount, clientUsersWhoLiked: comment.clientUsersWhoLiked })
			}
		},
		set_reply() {
			this.$store.commit('EDIT_GAME_UI_ACTIVE', { reply_to: this.comment })
			document.querySelector('#input-new-comment').focus()
		},
		show_editing() {
			this.edit_menu_visible = false
			this.editing_body = this.comment.body
			this.editing = true
			this.$nextTick(() => {
				this.$el.querySelector('textarea').focus()
			})
		},
		async save_edit() {
			if (this.parent && this.editing_body) {
				const input = {
					body: this.editing_body,
					commentableElementId: this.parent.id,
					commentableElementType: this.parent.elementType
				}
				const updated_comment = await Apollo.mutate('UPDATE_CLIENT_COMMENT', { id: this.comment.id, input })
				if (updated_comment) this.$store.commit('EDIT_COMMENT', { comment: this.comment, body: updated_comment.body })
			}
			this.editing = false
		},
		async delete_comment() {
			if (this.is_mine) {
				const is_deleted = await this.$store.dispatch('delete_comment', { id: this.comment.id })
				if (is_deleted) this.deleted = true
			}
			else if (this.is_reviewer) {
				const is_refused = await this.$store.dispatch('refuse_comment', { id: this.comment.id })
				if (is_refused) this.deleted = true
			}
		},
		close() {
			this.edit_menu_visible = false
			this.remove_visible = false
		}
	},
	components: { UiTextContent, CommentItem }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.comment-item
	margin 0 0 16px 0
	background-color #fff5f2
	border-bottom 2px solid #ffd5c8
	transition 0.5s easeOutQuart
	&.light
		border-bottom none
		margin 0
	&.deleted
		// transform translateX(-110%)
		height 0
		opacity 0
		margin 0
	// &.pending
	// 	box-shadow inset 0 0 0 2px #ffd5c8
	// 	border-bottom-color transparent
	// 	&:before
	// 		content 'Pending'
	// 		display block
	// 		width 100%
	// 		height 24px
	// 		line-height (@height + 2px)
	// 		background-image url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxkZWZzPjxwYXR0ZXJuIGlkPSJwYXR0ZXJuX0c2emkxQSIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgd2lkdGg9IjYiIGhlaWdodD0iNiIgcGF0dGVyblRyYW5zZm9ybT0icm90YXRlKDQ1KSI+PGxpbmUgeDE9IjAiIHk9IjAiIHgyPSIwIiB5Mj0iNiIgc3Ryb2tlPSIjRkZENUM4IiBzdHJva2Utd2lkdGg9IjgiLz48L3BhdHRlcm4+PC9kZWZzPiA8cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI3BhdHRlcm5fRzZ6aTFBKSIgb3BhY2l0eT0iMC41Ii8+PC9zdmc+')
	// 		color red
	// 		font-size 1.2rem
	// 		font-weight 700
	// 		text-align center
	// 		text-transform uppercase
	// 		border-bottom 2px solid #ffd5c8
	.comment-user
		padding 8px 12px 0 12px
		color red
		line-height 24px
		height 32px
		white-space nowrap
		.name
			display inline-block
			background url(../../../../assets/img/game-result/user.svg) left center no-repeat
			background-size 20px auto
			padding-left 28px
		.date
			float right
			margin 0 40px 0 0
			font-size 1.2rem
		.dots-button
			position absolute
			right 8px
			top 8px
			width 32px
			height 24px
			// background-color alpha(red, 10%)
			text-align center
			font-size 4rem
			line-height 2px
			cursor pointer
			&.delete
				height 32px
				top 4px
				background url(../../../../assets/img/comments/delete-comment-orange.svg) center center no-repeat
				background-size contain
	.comment-content
		padding 8px 12px 8px 40px
		>>> .ui-text-content
			line-height 20px
		.form-edit
			.textarea
				display block
				width 100%
				max-width 100%
				min-width 100%
				margin 0 0 8px 0
				border none
				background #fff
				box-shadow 0 0 0 2px red
				font inherit
				color inherit
				outline none
			.buttons
				text-align right
				.button
					display inline-block
					height 32px
					padding 0 12px
					margin 0 0 0 4px
					background-color red
					cursor pointer
					border none
					font inherit
					font-size 1.4rem
					font-weight 700
					color #fff
					text-transform uppercase
					line-height @height
					&.gray
						background-color #999
	.comment-footer
		margin 0 12px 0 40px
		padding 8px 0
		font-size 1.4rem
		border-top 1px solid gray
		color red
		line-height 24px
		flex flex-start center
		&.replies-visible
			border-bottom 1px solid gray
		.action
			display inline-block
			margin 0 8px 0 0
			cursor pointer
			&.right
				position absolute
				right 0
				top 8px
				margin 0 0 0 8px
			&.reply
				padding-left 24px
				background url(../../../../assets/img/game-result/comment-bubble.svg) left center no-repeat
				background-size 16px auto
			&.like
				padding-left 24px
				background url(../../../../assets/img/game-result/like-empty.svg) left center no-repeat
				background-size 16px auto
				&.liked
					background-image url(../../../../assets/img/game-result/like-full.svg)
			&.show-reply
				flex center center
				&:after
					content ''
					float right
					width 8px
					height 6px
					margin-left 8px
					width 0
					height 0
					border-style solid
					border-width 8px 6px 0 6px
					border-color red transparent transparent transparent
				&[data-nb="0"]
					cursor default
					&:after
						display none
		.dot
			display inline-block
			height 24px
			width 8px
			margin 0 8px 0 0
			flex center center
			&:before
				content ''
				display block
				width 6px
				height 6px
				background-color red
				border-radius 50%

	.replies
		padding 8px 0 8px 32px
		font-size 1.4rem
		line-height 14px
		.reply
			padding 8px 0
			.reply-user
				padding 0 12px
				margin 0 0 4px 0
				color red
				white-space nowrap
				.date
					margin-left 4px
					font-size 1.2rem
					font-weight 400
			.reply-content
				padding-left 24px
				>>> .ui-text-content
					line-height 18px

	.edit-menu
		position absolute
		right 0
		top 0
		background-color red
		height 100%
		max-height 96px
		width 180px
		transition 0.25s easeOutQuart
		&.edit-menu-enter, &.edit-menu-leave-to
			transform translateX(40px)
			opacity 0
		&.remove
			width 100%
			height 100%
		.remove-label
			position absolute
			left 8px
			top 24px
			color #fff
			text-transform uppercase
			padding-left 32px
			background url(../../../../assets/img/comments/delete-comment.svg) left center no-repeat
			background-size 32px auto
		.actions
			width 100%
			height 100%
			flex center center
			flex-direction column
			.action
				width 80px
				height 32px
				margin 2px 0
				border 1px solid #fff
				cursor pointer
				text-align center
				color #fff
				line-height @height
				text-transform uppercase
				font-weight 700
				&:hover
					box-shadow 0 0 0 1px #fff
					background-color alpha(#fff, 10%)
				.action-content
					absolute 0
					background center center no-repeat
					background-size 32px auto
					&.delete
						background-image url(../../../../assets/img/comments/delete-comment.svg)
					&.edit
						background-image url(../../../../assets/img/comments/edit-comment.svg)
			.close
				position absolute
				right 0
				top 0
				width 32px
				height 32px
				background url(../../../../assets/img/close.svg) center center no-repeat
				background-size 12px auto
				cursor pointer
.capitalize
	text-transform capitalize
</style>
