<template>
	<div v-if="current_tab" class="patient-report" :class="[ { tuto: tuto }, game.current.step.layout ]" :style="styles">
		<div class="tablet-background" ref="bg">
			<div class="top">
				<div class="speaker"></div>
			</div>
			<div class="bottom">
				<div class="home-button"></div>
			</div>
		</div>
		<div class="tabs-content">
			<div v-for="tab in tabs" class="tab-content" :class="{ active: tab == current_tab }">
				<div class="scroll" v-scrollbar>
					<div>
						<ui-text-content :html="current_tab.html" />
					</div>
				</div>
			</div>
		</div>
		<div class="tab-title">{{ current_tab.clientMedias[0].document.title }}</div>
		<div class="tabs">
			<div
				v-for="tab in tabs"
				class="tab"
				:class="[ { active: current_tab.id == tab.id } ]"
				:style="{ backgroundImage: 'url(' + $medias.get({ id: tab.clientMedias[0].id }) + ')' }"
				v-hammer:tap="(event)=> select(tab)"
			></div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiTextContent from '@/components/ui/ui-text-content'
import Feature from 'feature.js'
import {manageEventListeners} from "@/js/managers/manageEventListeners"

export default {
	name: 'patient-report',
	data() {
		return {
			styles: false,
			manageEventListeners: manageEventListeners()
		}
	},
	props: [ 'tuto' ],
	computed: {
		...mapGetters([ 'app', 'game' ]),
		tabs() {
			let tabs = this.game.ui.patient_report.contentTexts.filter((text) => {
				return text.tags && text.tags.includes('patient_report')
			})
			tabs.map((tab, i) => { tab.no = i })
			return tabs
		},
		current_tab() {
			return this.game.ui.active.patient_report_tab
		}
	},
	watch: {
		'game.current.step.layout'(newValue, oldValue) {
			this.$nextTick(() => { this.resize() })
		},
		'game.ui.patient_report'(newValue, oldValue) {
			this.select(this.tabs[0])
		}
	},
	methods: {
		select(tab) {
			// this.current_tab = tab
			this.$store.dispatch('edit_patient_report_tab', { tab: tab })
		},
		swipe_tab(e) {
			if (e.direction == 2) { // right
				let new_tab = this.tabs[this.current_tab.no+1]
				if (!new_tab) new_tab = this.tabs[0]
				this.select(new_tab)
			}
			else if (e.direction == 4) { // left
				let new_tab = this.tabs[this.current_tab.no-1]
				if (this.current_tab.no-1 < 0) new_tab = this.tabs[this.tabs.length-1]
				this.select(new_tab)
			}
		},
		resize() {
			if (this.app.device != 'mobile' && this.game.current.step.layout == 'presentation') {
				let delay = 0
				if (Feature.touch) delay = 100
				setTimeout(() => {
					let x = (window.innerWidth / 2) - (this.$refs.bg.clientWidth / 2) - 16
					let y = (window.innerHeight / 2) - (this.$refs.bg.clientHeight / 2) - 32
					this.styles = { transform: 'translate(' + Math.round(x) + 'px, -' + Math.round(y) + 'px)' }
				}, delay)
			} else
				this.styles = false
		}
	},
	mounted() {
		this.select(this.tabs[0])

		this.$nextTick(() => { this.resize() })
		this.manageEventListeners.add(window, 'resize', this.resize)
	},
	components: { UiTextContent },
	beforeDestroy() {
		this.manageEventListeners.destroy()
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.perf-normal, #app.perf-high
	.patient-report
		transition transform 0.35s easeOutQuart
	.patient-report-enter
		transform translateY(200%) scale(2)
	.patient-report-leave-to
		transform translateY(150%)


#app.mobile
	.patient-report
		left 0
		bottom 0
		max-width none
	.patient-report-enter
		transform translateY(150%)

#app.desktop, #app.tablet
	.patient-report
		&.tuto
			transform translateY(-32px)
		.tablet-background
			position absolute
			left -16px
			top -24px
			right -16px
			bottom -32px
			background-color blue_dark
			overflow hidden
			border-radius 16px
			box-shadow 0 4px 8px alpha(#000, 50%), inset 1px 1px alpha(#fff, 25%)
			&:before
				content ''
				position absolute
				left 0
				top 0
				bottom 0
				width 50%
				transform rotate(20deg) scale(1.7) translateX(-20%)
				background-color alpha(#fff, 20%)
			.top
				position absolute
				left 0
				right 0
				top 0
				height 24px
				display flex
				align-items center
				justify-content center
				.speaker
					display inline-block
					width 40px
					height 4px
					margin 0 4px
					border-radius 4px
					background-color darken(blue_dark, 15%)
			.bottom
				position absolute
				left 0
				right 0
				bottom 0
				height 32px
				display flex
				align-items center
				justify-content center
				.home-button
					display inline-block
					width 20px
					height 20px
					border-radius 50%
					background-color darken(blue_dark, 50%)
					&:before
						content ''
						position absolute
						left 6px
						top 6px
						right 6px
						bottom 6px
						border 1px solid alpha(#fff, 25%)
						border-radius 2px
		.tabs-content
			height 334px




.patient-report
	position absolute
	left (16px + 40px)
	bottom (32px + 40px)
	width 100%
	max-width 550px
	&.presentation
		left 16px
		bottom 32px
	&.tuto
		transform translateY(-64px)
	.tab-title
		position absolute
		left 0
		top 0
		right 0
		height 32px
		line-height @height
		text-align center
		color #fff
		background-color red
		&.red
			background-color red
		&.yellow
			background-color yellow
		&.teal
				background-color teal
		&.gray
			background-color gray
		&.blue
			background-color blue
	.tabs
		position absolute
		left 0
		top 32px
		right 0
		height 32px
		line-height @height
		display flex
		background-color red
		.tab
			display inline-block
			height 100%
			flex-grow 1
			cursor pointer
			background center center no-repeat
			background-size 24px 24px
			background-color yellow
			box-shadow inset -1px -1px 0 0 #fff
			&.red
				background-color red
				&.active
					box-shadow 0 8px 0 0 red
			&.yellow
				background-color yellow
				&.active
					box-shadow 0 8px 0 0 yellow
			&.teal
				background-color teal
				&.active
					box-shadow 0 8px 0 0 teal
			&.gray
				background-color gray
				&.active
					box-shadow 0 8px 0 0 gray
			&.blue
				background-color blue
				&.active
					box-shadow 0 8px 0 0 blue
			&.active
				background-color red
				border-bottom-left-radius 8px
				border-bottom-right-radius 8px
				box-shadow 0 8px 0 0 red
				&+ .tab
					box-shadow inset -1px -1px 0 0 #fff, inset 1px 0 0 0 #fff
	.tabs-content
		background-color red
		height 264px
		.tab-content
			padding-top 64px
			height 100%
			background-color #fff
			display none
			&.active
				display block
			.scroll
				height 100%
			.text-content
				padding-left 24px
				padding-top 24px
				padding-right 24px
				&:after
					content ''
					display block
					height 16px


</style>
