<template>
	<div class="comment-form">
		<div v-if="game.ui.active.reply_to" class="reply-to">
			<p class="title">{{ $t('reply_to') }}:</p>
			<ui-text-content :html="game.ui.active.reply_to.body" />
			<div class="close" v-hammer:tap="() => remove_reply_to()"></div>
		</div>
		<div v-show="!text" class="placeholder">{{ $t('share_your_thoughts') }}</div>
		<div class="input" id="input-new-comment" contenteditable @input="set_text"></div>
		<div class="button" :class="{ sendable: sendable }" v-hammer:tap="() => send()"></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiTextContent from '@/components/ui/ui-text-content'

export default {
	name: 'comment-form',
	data() {
		return {
			text: ''
		}
	},
	computed: {
		...mapGetters([ 'game' ]),
		sendable() {
			return this.text != ''
		},
	},
	methods: {
		set_text(e) {
			this.text = e.target.innerText.trim()
		},
		async send() {
			if (this.sendable) {
				let values = { body: this.text }
				if (this.game.ui.active.reply_to) {
					values.element_id = this.game.ui.active.reply_to.id
					values.element_type = this.game.ui.active.reply_to.elementType
				} else {
					values.element_id = this.game.current.game.id
					values.element_type = this.game.current.game.elementType
				}
				await this.$store.dispatch('send_comment', values)
				this.$store.dispatch('get_game_comments', { game_id: this.game.current.game.id })
				this.$el.querySelector('.input').innerText = ''
				this.text = ''
				this.remove_reply_to()
			}
		},
		remove_reply_to() {
			this.$store.commit('EDIT_GAME_UI_ACTIVE', { reply_to: false })
		}
	},
	components: { UiTextContent }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.comment-form
	position absolute
	left 0
	bottom 0
	right 0
	min-height (24px + 16px)
	padding 12px 0 6px 0
	background-color #fff
	box-shadow 0 -2px 0 0 red
	.reply-to
		position absolute
		left 0
		right 0
		bottom calc(100% + -2px)
		padding 8px 48px 8px 12px
		background-color red
		color #fff
		line-height 20px
		box-shadow 0 0 0 2px #fff
		border-radius 8px
		&:before
			content ''
			position absolute
			left calc(50% - 8px)
			bottom -8px
			width 0
			height 0
			border-style solid
			border-width 8px 8px 0 8px
			border-color red transparent transparent transparent
		.title
			font-size 1.2rem
			text-transform uppercase
			color alpha(#fff, 75%)
			height 20px
			white-space nowrap
		>>> .ui-text-content
			line-height 20px
		.close
			position absolute
			right 12px
			top 12px
			width 32px
			height 32px
			border-radius 50%
			background url(../../../../assets/img/close.svg) center center no-repeat
			box-shadow 0 0 0 2px #fff
			background-size 16px 16px
			cursor pointer
	.placeholder
		position absolute
		left 16px
		top 20px
		line-height 20px
		color #999
	.input
		display block
		width 100%
		min-height 36px
		line-height 20px
		padding 8px 40px 8px 16px
		outline none
		border-radius 18px
		box-shadow 0 0 0 1px #999
		&:focus
			box-shadow 0 0 0 2px red
	.button
		position absolute
		right 0
		bottom 4px
		width 40px
		height 40px
		background url(../../../../assets/img/game-result/send.svg) bottom 10px center no-repeat
		background-size 24px auto
		cursor not-allowed
		border-radius 50%
		opacity 0.5
		&.sendable
			opacity 1
			cursor pointer


</style>
