<template>
	<div class="home">

		<home-background :day_moment="app.day_moment" />

		<div class="rooms" :class="{ 'small-width': small_width }" :style="{ width: bg_width + 'px' }">
			<div class="room resources" v-hammer:tap="()=> goto({ name: 'resources' })">
				<div class="npc-resources-1" id="npc-resources-1"></div>
				<div class="npc-resources-2" id="npc-resources-2"></div>
				<div class="room-title">{{ $t('resources') }}</div>
			</div>
			<div class="room profile" v-hammer:tap="()=> goto({ name: 'office' })">

				<div class="item diplomas" :class="app.day_moment"></div>
				<div class="item files" :class="app.day_moment"></div>
				<div class="item computer" :class="app.day_moment"></div>
				<div class="item plant" :class="app.day_moment"></div>

				<div class="room-title">{{ $t('profile') }}</div>
			</div>
			<div class="room patients-cases" v-hammer:tap="()=> display_patients()">
				<div class="patient patient-0" id="layer_patient_0"></div>
				<div class="patient patient-1" id="layer_patient_1"></div>
				<div class="patient patient-2" id="layer_patient_2"></div>
				<div class="patient patient-3" id="layer_patient_3"></div>
				<div class="patient patient-4" id="layer_patient_4"></div>
				<div class="room-title">{{ $t('patient_case') }}</div>
			</div>
		</div>

		<div v-if="isCommentsManageable() && !app.administrator_access_visible" class="comments-button" v-hammer:tap="() => toggle_administrator_access()">
			<span class="text">{{ $t('administrator_access') }}</span>
			<span v-if="administrator_access_warning" class="warning"></span>
		</div>

		<transition name="comments">
			<administrator-access v-if="isCommentsManageable() && app.administrator_access_visible" v-on:close="toggle_administrator_access()" />
		</transition>

		<transition name="dialog-box">
			<patients v-if="patients.patients_visible" />
		</transition>

		<transition name="dialog-box">
			<dialog-box v-if="game.ui.active.modal" :modal="game.ui.active.modal" />
		</transition>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'
import HomeBackground from '@/components/home-background'
import Patients from '@/components/patients/patients'
import DialogBox from '@/components/game/dialog-box'
import AdministratorAccess from '@/components/administrator-access'
import {manageLottie} from "@/js/managers/manageLottie"
import {manageEventListeners} from "@/js/managers/manageEventListeners"

export default {
	name: 'home',
	data() {
		return {
			animations: [],
			bg_width: 0,
			small_width: false,
			manageEventListeners: manageEventListeners()
		}
	},
	computed: {
		...mapGetters([ 'app', 'game', 'patients', 'logged_user' ]),
		administrator_access_warning() {
			if (this.game.pending_comments.length > 0) return true
			return !!this.app.research_papers_licences.find(
				p => p
					&& p.accessibleSettings
					&& !p.accessibleSettings.unlimitedAccess
					&& p.accessibleSettings.licenseFor - p.accessibleSettings.accessCount < 10
			)
		}
	},
	watch: {
		'patients.patients_visible'(newValue) {
			if (newValue) {
				for (let i in this.animations) {
					this.animations[i].pause()
				}
			} else {
				for (let i in this.animations) {
					this.animations[i].play()
				}
			}
		},
		'game.games'(newValue) {
			// 2 pour ne pas compter les deux personnes du 1er étage
			if (this.animations.length <= 2 && newValue && newValue.length) {
				this.show_games_animations()
			}
		},
	},
	methods: {
		toggle_administrator_access() {
			this.$store.commit('EDIT_APP_STATE', { administrator_access_visible: !this.app.administrator_access_visible })
		},
		isCommentsManageable() {
			if (this.logged_user && this.logged_user.reviewer) return true
			const { logged_user: { admin = null, roles = [] } = {} } = this
			if (!admin && !roles.length) return false
			return admin || roles.includes('reviewer')
		},
		show_games_animations() {
			[
				'Old_Patient_Sit_Idle_Animation',
				'Old_Woman_Sit_Idle_Animation',
				'Yellow_Shirt_Guy_Sit_Idle_Animation',
				'Pink_Shirt_Girl_Sit_Idle_Animation',
				'Man_Sit_Idle_Animation',
			]
				.map(x => this.$medias.findBySlug({ slug: x }))
				.forEach((path, index) => {
					const ref = document.querySelector('#layer_patient_' + index)
					this.animations.push(
						manageLottie.create({
							container: ref,
							path,
							renderer: 'svg',
							loop: true,
							autoplay: true,
							rendererSettings: {
								preserveAspectRatio: 'xMidYMax slice',
							}
						})
					)
				})
			for (let anim of this.animations) {
				this.manageEventListeners.add(anim, 'DOMLoaded', () => {
					this.loaded++
				})
			}

		},
		display_patients() {
			this.$store.dispatch('set_patients_visible', { patients_visible: true })
		},
		get_game_image(game) {
			let url = false
			let media = game.clientMedias.find((m) => { return m.tags.includes('patient_icon') })
			if (media) return media.url
			return false
		},
		resize() {
			const $clinic = this.$el.querySelector('#bg-clinic')
			if ($clinic) {
				setTimeout(() => {
					this.bg_width = $clinic.clientWidth
					this.small_width = this.$el.clientWidth < Math.round(this.bg_width * 22.95 / 100)
					this.$emit('loaded')
				}, 100)
			}
		},
		show_unavailable_modal() {
			this.$store.dispatch('set_active_modal', {
				modal: {
					title: "Not available",
					text: "This feature is not available in this demo version.",
					has_close: true
				}
			})
		},
		goto(data) {
			this.$router.push(data)
		},
		async get_research_papers_licences() {
			const r = await Apollo.query('CLIENT_FILE_ACCESSIBLE')
			if (r && r.data) this.$store.commit('EDIT_APP_STATE', { research_papers_licences: r.data })
		}
	},
	async mounted() {
		await this.$store.dispatch('set_game_filters', { targetModel: 'Client::Game', property: 'all' })
		await this.$store.dispatch('set_game_filters_with', {
			values: [ this.$store.getters.patients.filters, this.$store.getters.patients.search ]
		})
		await this.$store.dispatch('get_games_filtered', { scopes: [ 'incomplete' ] })
		this.manageEventListeners.add(window, 'resize', this.resize)
		this.animations.push(
			manageLottie.create({
				container: this.$el.querySelector('#npc-resources-2'),
				path: this.$medias.findBySlug({ slug: 'NPC_Library_Brown_Hair_Girl' }),
				renderer: 'svg',
				loop: true,
				autoplay: true,
				rendererSettings: {
					preserveAspectRatio: 'xMidYMax slice',
				}
			})
		)

		let file = 'NPC_Library_Black_Hair_Girl'
		if (this.app.day_moment === 'night') file = 'NPC_Library_Night_Black_Hair_Girl'

		this.animations.push(
			manageLottie.create({
				container: this.$el.querySelector('#npc-resources-1'),
				path: this.$medias.findBySlug({ slug: file }),
				renderer: 'svg',
				loop: true,
				autoplay: true,
				rendererSettings: {
					preserveAspectRatio: 'xMidYMax slice',
				}
			})
		)

		await this.get_research_papers_licences()
		this.resize()
	},
	components: { DialogBox, Patients, AdministratorAccess, HomeBackground },
	beforeDestroy() {
		this.animations.length = 0
		this.manageEventListeners.destroy()
	}
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'


#app.mobile
	.room-title
		height 24px
		line-height @height
	.comments-button
		right 16px
		top 16px
		height 40px
		line-height @height
		padding 0 16px
		.warning
			width 24px
			height 24px
			margin 0 0 0 8px

#app.not-touch
	.home
		.rooms
			.room:hover
				&:after
					content ''
					position absolute
					left 0
					top 0
					right 0
					bottom 0
					background-color alpha(#50BBE2, 20%)
.home
	position absolute
	left 0
	top 0
	right 0
	bottom 0
	display flex
	flex-direction column
	justify-content center
	align-content center
	padding 32px
	background-color dark
	color #fff
	text-align center
	will-change transform

	.rooms
		position absolute
		top 0
		bottom 0
		left 50%
		transform translateX(-50%)
		&.small-width
			.room
				.room-title
					// position fixed
					right auto
					left 50%
					transform translateX(-50%)
					border-radius 32px
		.room
			position absolute
			left 50%
			width 22.95%
			height 26.4%
			transform translateX(-50%)
			cursor pointer
			&.profile
				bottom 64.6%
				.item
					position absolute
					background center center no-repeat
					background-size contain
					&.diplomas
						left 42%
						top 8%
						width 15%
						height 40%
						background-image url(../assets/img/profile/diploma.svg)
						&.night
							background-image url(../assets/img/profile/diploma-night.svg)
					&.files
						left 61%
						top 65%
						width 10%
						height 20%
						background-image url(../assets/img/profile/files.svg)
						&.night
							background-image url(../assets/img/profile/files-night.svg)
					&.computer
						left 45%
						top 60%
						width 15%
						height 25%
						background-image url(../assets/img/profile/computer.svg)
						&.night
							background-image url(../assets/img/profile/computer-night.svg)
					&.plant
						left 70%
						top 65%
						width 10%
						height 20%
						background-image url(../assets/img/profile/plant.svg)
						&.night
							background-image url(../assets/img/profile/plant-night.svg)
			&.resources
				bottom 36.3%
				.npc-resources-1
					position absolute
					left 53.5%
					top 37.5%
					width 7%
					height 46%
					// background-color alpha(red, 50%)
					// opacity 0
				.npc-resources-2
					position absolute
					left 70%
					top 35%
					width 14%
					height 55%
					transform scaleX(-1)
					// background-color alpha(red, 50%)
			&.patients-cases
				bottom 8%
				// cursor default
				// &:hover
				// 	background-color transparent
				.patient
					position absolute
					left 24%
					bottom 11.5%
					height 55%
					width 10%
					&:before
						// content ''
						position absolute
						left 0
						top 0
						right 0
						bottom 0
						background-color alpha(red, 50%)
					&.patient-1
						left 33.5%
					&.patient-2
						left 42.7%
					&.patient-3
						left 51.8%
					&.patient-4
						left 61%
			&:active
				&:after
					content ''
					position absolute
					left 0
					top 0
					right 0
					bottom 0
					background-color alpha(#50BBE2, 20%)
				.room-title
					z-index 2
			.room-title
				position absolute
				top 8px
				right 0
				height 28px
				width 180px
				text-align center
				background-color red
				color #fff
				line-height @height
				border-top-left-radius 32px
				border-bottom-left-radius 32px

	.bottom-menu
		position absolute
		left 0
		right 0
		bottom 0
		height 7%
		// background-color alpha(red, 25%)
		display flex
		align-content center
		align-items center
		justify-content center
		// font-size 1.2rem
		.link
			display inline-block
			cursor pointer
			padding 8px 12px
			&:hover
				text-decoration underline
	.comments-button
		position absolute
		right 32px
		top 32px
		height 56px
		padding 0 24px
		border-radius 64px
		background-color dark
		flex center center
		cursor pointer
		line-height @height
		color #fff
		text-transform uppercase
		&:hover
			background-color darken(dark, 25%)
		.warning
			width 32px
			height 32px
			margin 0 0 0 16px
			background url(../assets/img/administrator-access/warning.svg) center center no-repeat
			background-size contain
	.loading-screen
		absolute 0
		background dark url(../assets/img/loader.gif) center center no-repeat
		background-size 200px auto
		z-index 2

@keyframes arrow_down
	0%, 100%
		transform translate3d(0, 0, 0)
	50%
		transform translate3d(0, -12px, 0)

@keyframes floating
	0%, 100%
		transform scale(1)
	5%
		transform scale(1.1)
	10%
		transform scale(1)
	15%
		transform scale(1.1)
	20%
		transform scale(1)


</style>
