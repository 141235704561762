<template>
	<div class="answers-selector" :class="{ tuto: tuto }">
		<div class="hint">{{ $t('select_your_answer') }} :</div>
		<div class="answer-letters">
			<answer-letter
				v-for="a in answers"
				:answer="a.answer"
				:letter="a.letter"
				:color="a.color"
				:tuto="tuto"
				:key="a.answer.id"
			/>
		</div>
		<answer-text
			v-if="active_answer"
			:answer="active_answer.answer"
			:color="active_answer.color"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import AnswerLetter from '@/components/game/answer-letter'
import AnswerText from '@/components/game/answer-text'
import { getArrayOfCharCodes, shuffle } from '@/js/utils/utils'

export default {
	name: 'answers-selector',
	data() {
		return {
			color: ''
		}
	},
	props: [ 'tuto' ],
	computed: {
		...mapGetters([ 'game' ]),
		answers() {
			return (this.game.current.question.suffleAnswer ? shuffle(this.game.current.question.answers) : this.game.current.question.answers)
				.map(answer => ({ answer }))
				.map((x, index) => Object.assign(x, { letter: getArrayOfCharCodes()[index] }))
				.map((x, index) => Object.assign(x, { color: [ 'blue', 'yellow', 'red', 'purple', 'darkblue', 'mandy' ][index]}))
		},
		active_answer() {
			return this.answers.find((a) => a && a.answer == this.game.ui.active.answer)
		}
	},
	methods: {},
	components: { AnswerLetter, AnswerText }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

#app.perf-normal, #app.perf-high
	.answers-selector-enter-active, .answers-selector-leave-active
		transition 0.35s easeOutQuart
		>>> .answer-letter .line
			opacity 0
		>>> .answer-text
			opacity 0
	.answers-selector-enter, .answers-selector-leave-to
		transform translateY(100%)

#app.desktop, #app.tablet
	.answers-selector
		left auto
		right (64px - 16px + 64px)
		bottom (64px - 8px)
		width 400px
		height 80px
		padding 0 32px 0 24px
		border-bottom-left-radius 8px
		border-bottom-right-radius 8px
		&.answers-selector-enter, &.answers-selector-leave-to
			transform translateX(64px)
			opacity 0
		.hint
			border-top-left-radius 8px
			border-top-right-radius 8px

.answers-selector
	position absolute
	left 0
	right 0
	bottom 0
	height 64px
	background-color dark
	&.tuto
		transform translateY(-64px)
	.answer-letters
		height 100%
		display flex
		align-items center
		justify-content space-around
		.answer-letter
			margin 0 8px
	.hint
		position absolute
		left 0
		bottom 100%
		right 0
		padding 16px
		font-size 1.4rem
		text-align center
		background-color dark
		color lighten(dark, 80%)
		text-transform uppercase


</style>
