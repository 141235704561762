<template>
	<div
		class="answer-letter"
		:class="[ color, { visible: visible, selected: selected } ]"
		v-hammer:tap="()=> set_answer_visible()"
	>
		<div class="line"></div>
		<div class="letter">{{ letter }}</div>
		<transition name="check">
			<div v-show="selected" class="check"></div>
		</transition>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'answer-letter',
	data() {
		return {

		}
	},
	props: [ 'answer', 'letter', 'color', 'tuto' ],
	computed: {
		...mapGetters([ 'game' ]),
		visible() {
			return this.game.ui.active.answer == this.answer
		},
		selected() {
			return this.game.ui.active.selected_answers.findIndex((a) => { return a.id === this.answer.id }) > -1
		}
	},
	methods: {
		set_answer_visible() {
			if (this.visible)
				this.$store.dispatch('set_active_answer', { answer: false })
			else {
				this.$store.dispatch('set_active_answer', { answer: this.answer })
				if (this.tuto && !this.game.tuto.done) this.$store.dispatch('next_tuto_step')
			}
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.answer-letter
	display block
	&.red
		&.visible
			.letter
				background-color red
				box-shadow inset 0 0 0 4px red_light
		.letter
			box-shadow inset 0 0 0 3px red_light
		.line
			background-color red_light
	&.blue
		&.visible
			.letter
				background-color blue
				box-shadow inset 0 0 0 4px blue_light
		.letter
			box-shadow inset 0 0 0 3px blue_light
		.line
			background-color blue_light
	&.yellow
		&.visible
			.letter
				background-color yellow
				box-shadow inset 0 0 0 4px yellow_light
		.letter
			box-shadow inset 0 0 0 3px yellow_light
		.line
			background-color yellow_light
	&.purple
		&.visible
			.letter
				background-color purple
				box-shadow inset 0 0 0 4px purple_light
		.letter
			box-shadow inset 0 0 0 3px purple_light
		.line
			background-color purple_light
	&.darkblue
		&.visible
			.letter
				background-color darkblue
				box-shadow inset 0 0 0 4px darkblue_light
		.letter
			box-shadow inset 0 0 0 3px darkblue_light
		.line
			background-color darkblue_light
	&.mandy
		&.visible
			.letter
				background-color mandy
				box-shadow inset 0 0 0 4px mandy_light
		.letter
			box-shadow inset 0 0 0 3px mandy_light
		.line
			background-color mandy_light
	&.visible
		.letter
			border-width 5px
		.line
			display block
	.line
		display none
		position absolute
		left calc(50% - 2px)
		bottom 44px
		height (64px + 8px)
		width 4px
		background-color blue
		transition 0.25s easeOutQuart
	.letter
		width 44px
		height 44px
		background-color dark_light
		border-radius 50%
		line-height @height
		text-align center
		transition 0.25s easeOutQuart
		color #fff
		font-weight 500
		font-size 1.6rem
		cursor pointer
	.check
		absolute -1px
		flex center center
		border-radius 50%
		background #689F38 url(../../assets/img/check-white.svg) center center no-repeat
		background-size 24px 24px
		box-shadow inset 0 0 0 4px lighten(#689F38, 25%)
		cursor pointer
		&.check-enter-active, &.check-leave-active
			transition 0.3s easeOutQuart
			z-index 10
		&.check-enter
			transform scale(5)
			opacity 0
		&.check-leave-to
			transform scale(2)
			opacity 0



</style>
