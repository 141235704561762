<template>
	<div class="licence" :class="{ warning: is_warning }">
		<div class="icon"></div>
		<div class="left">
			<div v-if="licence.document && licence.document.title" class="title">{{ licence.document.title }}</div>
			<div v-if="licence.document && licence.document.author" class="info author"><span class="label">{{ $t('written_by') }} :</span> {{ licence.document.author }}</div>
			<!-- <div v-if="licence.document && licence.document.author" class="info availability"><span class="label">Available to :</span> General Practice, Oncology</div> -->
			<div v-if="published_on" class="info availability"><span class="label">{{ $t('published_on') }} :</span> {{ published_on }}</div>
			<!-- <div class="buttons">
				<div class="more-button">More about this research paper <span class="arrow"></span></div>
			</div> -->
		</div>
		<div class="right">
			<div class="remaining">
				<div v-if="!unlimited_access" class="value">{{ remaining_licences }}</div>
				<div v-else class="value">∞</div>
				<div class="label"><span v-html="$t('remaining_licence')"></span><span v-if="remaining_licences > 1">s</span></div>
			</div>
			<div class="add-new-licences" :class="{ 'unlimited-access': unlimited_access, loading }">
				<div class="label">{{ $t('add_new_licences') }}</div>
				<div class="input-number">
					<div class="arrow prev" v-hammer:tap="decrement"></div>
					<input type="number" class="input" v-model="new_licences">
					<div class="arrow next" v-hammer:tap="increment"></div>
				</div>
				<div v-show="!confirming" class="buttons" key="1">
					<div class="button green" v-hammer:tap="() => confirming = true">{{ $t('add') }} <span class="plus">+</span></div>
				</div>
				<div v-show="confirming" class="buttons confirm" key="2">
					<div class="button green" v-hammer:tap="() => submit()">{{ $t('confirm') }}</div>
					<div class="button red" v-hammer:tap="() => confirming = false">{{ $t('deny') }}</div>
				</div>
				<div class="unlimited-access" v-hammer:tap="toggle_unlimited_access">
					<div class="checkbox" :class="{ checked: unlimited_access }"></div>
					<div class="label">{{ $t('unlimited_access') }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'

export default {
	name: 'licence',
	data() {
		return {
			new_licences: 0,
			unlimited_access: false,
			confirming: false,
			loading: false
		}
	},
	props: {
		licence: { default: null }
	},
	computed: {
		published_on() {
			if (this.licence.document) {
				let d = new Date(Date.parse(this.licence.document.published_on))
				return d.toISOString().split('T')[0]
			}
			return false
		},
		remaining_licences() {
			return this.licence.accessibleSettings ? this.licence.accessibleSettings.licenseFor - this.licence.accessibleSettings.accessCount : 0
		},
		is_warning() {
			return !this.licence.accessibleSettings.unlimitedAccess && this.licence.accessibleSettings.licenseFor - this.licence.accessibleSettings.accessCount < 10
		}
	},
	methods: {
		increment() {
			this.confirming = false
			this.new_licences++
		},
		decrement() {
			this.confirming = false
			this.new_licences--
		},
		async toggle_unlimited_access() {
			this.new_licences = 0
			this.unlimited_access = !this.unlimited_access
			await this.submit()
		},
		async submit() {
			this.loading = true
			const r = await Apollo.mutate('CLIENT_FILE_ACCESSIBLE_SETTING', {
				id: this.licence.id,
				input: { unlimitedAccess: this.unlimited_access, licenseFor: parseInt(this.licence.accessibleSettings.licenseFor) + parseInt(this.new_licences) }
			})
			if (r && r.accessibleSettings) {
				this.$store.commit('EDIT_RESEARCH_PAPER_LICENCE', { licence: this.licence, accessibleSettings: r.accessibleSettings })
				this.new_licences = 0
				this.unlimited_access = r.accessibleSettings.unlimitedAccess
			}
			this.confirming = false
			this.loading = false
		}
	},
	created() {
		if (this.licence.accessibleSettings) {
			this.unlimited_access = this.licence.accessibleSettings.unlimitedAccess
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile
	.licence
		flex-direction column
		.icon
			left auto
			right 16px
		.left, .right
			width 100%
			padding 0
		.left
			margin 0 0 8px 0
			.title
				padding-right 56px


.licence
	margin 0 0 24px 0
	background-color #484C57
	border-radius 16px
	padding 16px
	flex flex-start flex-start
	color #fff
	box-shadow 0px 5px 2px alpha(#000, 30%)
	&.warning
		.icon
			background-image url('../../assets/img/administrator-access/red-file-white-borders.svg')
	.icon
		position absolute
		left 16px
		top 16px
		width 56px
		height 56px
		background url('../../assets/img/administrator-access/gray-file-white-borders.svg') center center no-repeat
		background-size 32px
	.left
		width calc(100% - 220px)
		padding 0 16px 0 72px
		.title
			flex flex-start center
			height 56px
			margin 0 0 8px 0
			font-size 2rem
			line-height 1em
			text-transform uppercase
			border-bottom 1px solid #ccc
		.info
			margin 0 0 8px 0
			.label
				display inline-block
				width 110px
		.buttons
			.more-button
				display inline-block
				height 40px
				padding 0 40px 0 16px
				border 1px solid #ccc
				border-radius 8px
				line-height @height
				cursor pointer
				&:hover
					background-color alpha(#fff, 10%)
				.arrow
					position absolute
					right 16px
					top 14px
					width 0
					height 0
					border-style solid
					border-width 12px 8px 0 8px
					border-color #fff transparent transparent transparent


	.right
		width 220px
		.remaining
			height 56px
			margin 0 0 8px 0
			padding 0 16px
			flex flex-start center
			background-color #EFF3FE
			color #484C57
			.value
				margin 0 8px 0 0
				font-size 5rem
				line-height 1em
			.label
				font-size 1.8rem
				line-height 1.2em
				text-transform uppercase
		.add-new-licences
			background-color #5A5F6E
			text-align center
			padding 8px
			&.unlimited-access
				&:after
					content ''
					position absolute
					left 4px
					right 4px
					top 4px
					bottom 40px
					background-color alpha(#5A5F6E, 90%)
			&.loading
				&:after
					content ''
					position absolute
					left 4px
					right 4px
					top 4px
					bottom 4px
					background alpha(#5A5F6E, 90%) url('../../assets/img/button-loader.svg') center center no-repeat
					background-size 48px
					cursor wait
			> .label
				margin 0 0 4px 0
				text-transform uppercase
			.input-number
				flex center center
				height 40px
				margin 0 0 8px 0
				.arrow
					width @height
					height @height
					flex center center
					cursor pointer
					user-select none
					&.prev:after
						content ''
						display inline-block
						width 0
						height 0
						border-style solid
						border-width 12px 16px 12px 0
						border-color transparent #fff transparent transparent
					&.next:after
						content ''
						display inline-block
						width 0
						height 0
						border-style solid
						border-width 12px 0 12px 16px
						border-color transparent transparent transparent #fff
					&:active
						transform translateY(1px)
				.input
					height @height
					width (64px)
					border none
					background none
					font inherit
					color inherit
					outline none
					-webkit-appearance textfield
					-moz-appearance textfield
					appearance textfield
					border-bottom 1px solid #ccc
					text-align center
					font-size 3rem
					line-height 1em
					&::-webkit-inner-spin-button, 
					&::-webkit-outer-spin-button
						-webkit-appearance none
						margin 0
					&:hover, &:focus
						background-color alpha(#fff, 10%)
						border-color #fff
			.buttons
				flex center center
				margin 0 0 8px 0
				&.confirm
					.button
						width calc(50% - 8px)
						margin 0 4px
				.button
					width calc(100% - 8px)
					margin 0 auto
					background-color #7FAE51
					height 32px
					line-height @height
					padding 0 0 8px 0
					cursor pointer
					user-select none
					&.red
						background-color #D64348
						&:hover
							background-color darken(@background-color, 10%)
						&:active
							background-color darken(@background-color, 10%)
							transform translateY(2px)
					&:hover
						background-color darken(@background-color, 10%)
					&:active
						background-color darken(@background-color, 10%)
						transform translateY(2px)
			.unlimited-access
				flex flex-start center
				border-top 1px solid #777
				padding 8px 0 0 8px
				cursor pointer
				&:hover .checkbox
					background-color alpha(#fff, 10%)
				.checkbox
					width 24px
					height 24px
					margin 0 8px 0 0
					border 2px solid #fff
					&.checked
						background-color #fff
						box-shadow inset 0 0 0 2px #5A5F6E
				.label
					text-transform uppercase

</style>
