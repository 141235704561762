<template>
	<div id="main">
		<div class="view view-home">

			<transition name="view">
				<home v-if="!game.current.step" @loaded="$emit('loaded')" />
				<game v-else @loaded="$emit('loaded')" />
			</transition>

			<transition name="main-buttons">
				<div v-if="!patients.patients_visible && !app.administrator_access_visible" class="main-buttons">
					<div class="button settings" title="Settings" v-hammer:tap="()=> show_settings()"></div>
					<div class="button logout" title="Log out" v-alert-confirm="{
						title: $t('alert_logout_title'),
						text: $t('alert_logout_text'),
						confirm_text: $t('alert_logout_confirm_text'),
						cancel_text: $t('alert_logout_cancel_text'),
						confirm_dispatch: 'logout'
					}"></div>
				</div>
			</transition>

			<transition name="settings">
				<settings v-if="app.settings" />
			</transition>

			<ui-offline-bar />

		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Game from '@/components/game'
import Home from '@/components/home'
import UiOfflineBar from '@/components/ui/ui-offline-bar'
import Settings from '@/components/settings'
import { LANGUAGE } from '@/js/factory/language'

export default {
	name: 'view-home',
	data() {
		return {

		}
	},
	computed: {
		...mapGetters([ 'app', 'game', 'patients' ])
	},
	methods: {
		show_settings() {
			this.$store.dispatch('set_settings', { settings: true })
		},
		logout() {
			// this.$store.dispatch('removeUser')
			// this.$store.dispatch('removeAccessToken')
			// this.$store.commit('EDIT_USER_STATE', { logged_user: false })
			// this.$router.push({ name: 'login' })
		},
	},
	components: { Game, Home, UiOfflineBar, Settings }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'

#main
	transition 0.25s easeOutQuart
	transition-delay 0.25s

.main-buttons
	position absolute
	left 0
	top 16px
	padding 4px 0
	width 48px
	background dark
	border-top-right-radius 8px
	border-bottom-right-radius 8px
	cursor pointer
	transition 0.25s easeOutQuart
	&.main-buttons-enter, &.main-buttons-leave-to
		transform translateX(-110%)
	.button
		height 48px
		width 48px
		&.settings
			background url(../assets/img/settings.svg) center center no-repeat
			background-size 24px 24px
		&.logout
			background url(../assets/img/log-out.svg) center center no-repeat
			background-size 24px 24px
			&:after
				content ''
				position absolute
				left 25%
				right 25%
				height 2px
				background-color alpha(#fff, 25%)
				border-radius 4px
		&:hover
			opacity 0.5

</style>
