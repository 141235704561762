<template>
	<div class="learn-more">
		<div class="bg" v-hammer:tap="()=> close()"></div>
		<div class="learn-more-content">
			<div class="modal">
				<hooper :itemsToShow="1" :centerMode="true" :wheelControl="false">
					<slide v-for="(slide, index) in learn_more.slides" :key="index">
						<div class="learn-more-slide-content">
							<div class="learn-more-text scrollable-bottom" v-scrollbar>
								<div>
									<div style="max-height: 350px">
										<ui-text-content :html="slide" />
									</div>
								</div>
							</div>
						</div>
					</slide>
					<hooper-navigation slot="hooper-addons"></hooper-navigation>
					<hooper-pagination slot="hooper-addons"></hooper-pagination>
				</hooper>
				<div class="learn-more-close" v-hammer:tap="()=> close()"></div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiTextContent from '@/components/ui/ui-text-content'
import { Hooper, Slide, Navigation as HooperNavigation, Pagination as HooperPagination } from 'hooper'
import 'hooper/dist/hooper.css'

export default {
	name: 'learn-more',
	props: [ 'learn_more' ],
	computed: {
		...mapGetters([ 'game' ])
	},
	methods: {
		close() {
			this.$store.dispatch('set_active_learn_more', { learn_more: false })
		}
	},
	components: { UiTextContent, Hooper, Slide, HooperNavigation, HooperPagination }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.perf-normal, #app.perf-high
	.learn-more-enter-active,
	.learn-more-leave-active,
	.learn-more-enter-active .learn-more-content,
	.learn-more-leave-active .learn-more-content
		transition 0.25s easeOutQuart

	.learn-more-enter, .learn-more-leave-to
		opacity 0

	.learn-more-enter .learn-more-content
		transform translateY(-50px)
		opacity 0
	.learn-more-leave-to .learn-more-content
		transform translateY(-50px)
		opacity 0


#app.touch
	.learn-more
		>>> .hooper-navigation
			display none
// #app.desktop, #app.tablet
// 	.learn-more
// 		.learn-more-content
// 			.modal
// 				width 400px

#app.mobile
	.learn-more
		z-index 2

.learn-more
	position absolute
	left 0
	top 0
	right 0
	bottom 0
	display flex
	align-items center
	justify-content center
	// background-color alpha(dark, 80%)
	.bg
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		background-color alpha(dark, 80%)
	.learn-more-content
		width 100%
		max-width 500px
		height 80%
		.modal
			width 100%
			height 100%
			// background-color #fff
			.hooper
				height 100%
				>>> .hooper-track
					height calc(100% - 24px)
			.learn-more-slide-content
				background-color #fff
				padding 24px 16px
				margin 0 16px
				height 100%
				.learn-more-text
					text-align left
					color dark
					height 100%
					// height 500px
					.learn-more-text-content
						max-height 450px
						overflow auto
			.learn-more-close
				position absolute
				// left calc(50% - 16px)
				right 18px
				top -16px
				height 32px
				width 32px
				background dark url(../../assets/img/close.svg) center center no-repeat
				background-size 50% 50%
				border-radius 50%
				box-shadow 0 0 0 2px #fff
				cursor pointer
				line-height @height
				text-align center
				color #fff
				font-size 2rem
			.learn-more-button
				position absolute
				left 50%
				top calc(100% + 32px)
				transform translateX(-50%)

>>> .hooper-pagination
	padding 0
	height 24px
	.hooper-indicator
		height 8px
		width 8px
		background-color #fff
		opacity 0.25
		&.is-active
			background-color #fff
			opacity 1

>>> .hooper-navigation
	position absolute
	left 0
	right 0
	bottom 24px
	.hooper-prev, .hooper-next
		width 40px
		height 40px
		padding 0
		margin 0 18px
		background-color dark
		box-shadow 0 0 0 2px #fff
		border-radius 50%
		&.is-disabled
			opacity 1
			.icon
				opacity 0.3
		svg
			display block
			width 40px
			height 40px
			fill #fff

</style>
