<template>
	<div class="page-content-comment">
		<div class="page-title">{{ $t('comments') }}</div>

		<div class="page-scroll" v-scrollbar:scrollArrows>
			<div>
				<div class="comments">
					<comment-item v-for="comment in comments" :key="comment.id" :comment="comment" :parent="game.current.game" />
				</div>
			</div>
		</div>

		<comment-form />

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import CommentItem from '@/components/game/game-result/pages/comment-item'
import CommentForm from '@/components/game/game-result/pages/comment-form'

export default {
	name: 'page-comment',
	data() {
		return {

		}
	},
	computed: {
		...mapGetters([ 'game' ]),
		comments() {
			return this.game.current.comments.filter((c) => { return c.approvalState != 'refused' })
		}
	},
	methods: {
		get_text(slug) {
			let t = ''
			let texts = this.game.current.step.contentTexts.filter((t) => t.tags.includes(slug))
			for (var i in texts) {
				if (texts[i].html) t += texts[i].html
			}
			if (t) return t
			return ''
		},
	},
	components: { CommentItem, CommentForm }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.page-content-comment
	height 100%
	.page-scroll
		height 'calc(100% - %s)' % (48px + 54px)



</style>
