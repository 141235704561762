<template>
	<div class="game-result">
		<div class="bg"></div>
		<div class="content">
			<div class="main-title">{{ $t('end_of_scenario') }}</div>
			<div class="pages">
				<page :class="{ active: active_tab == 'result' }" :style="{ zIndex: tabs.result.zIndex }" name="result" @change_tab="set_active_tab" />
				<page :class="{ active: active_tab == 'recap' }" :style="{ zIndex: tabs.recap.zIndex }" name="recap" @change_tab="set_active_tab" />
				<page :class="{ active: active_tab == 'comment' }" :style="{ zIndex: tabs.comment.zIndex }" name="comment" @change_tab="set_active_tab" />
				<div
					v-if="openFeedbackWindow"
					class="feedback-window"
				>
					<div class="issue-title">{{ $t('report_issue_title') }}</div>
					<div class="close" v-hammer:tap="() => closeFeedbackWindow()"></div>
					<textarea v-model="issue" :placeholder="$t('enter_your_message')"></textarea>
					<span class="char-counter" :class="getCounterClass">{{ issue.length }} / {{ charMaximum }}</span>
					<div
						v-if="issue.length >= charMinimum"
						class="send-message"
						v-hammer:tap="() => send_feedback()"
						v-html="$t('send_message')"
					></div>
				</div>
				<div v-if="messageSent" class="message-sent shorter">
					<div class="issue-title">{{ $t('message_sent') }}</div>
					<div class="text">{{ $t('thank_you') }}</div>
				</div>
			</div>
			<div class="buttons">
				<div class="button issue" v-hammer:tap="()=> enterFeedback()" v-html="$t('report_issue')"></div>
				<div class="button redo" v-hammer:tap="()=> restart()"></div>
				<div class="button home" v-hammer:tap="()=> go_home()"></div>
			</div>
		</div>
	</div>
</template>

<script>
import Apollo from '@/apollo/apollo-client'
import { mapGetters } from 'vuex'
import Page from '@/components/game/game-result/page'

export default {
	name: 'game-result',
	data() {
		return {
			active_tab: false,
			issue: '',
			messageSent: false,
			openFeedbackWindow: false,
			charMinimum: 1,
			charMaximum: 800,
			tabs: {
				result: { zIndex: 3 },
				recap: { zIndex: 2 },
				comment: { zIndex: 1 }
			}
		}
	},
	computed: {
		...mapGetters([ 'game', 'user' ]),
		getCounterClass() {
			const { charMinimum, issue } = this
			return issue.length < charMinimum
				? 'bad'
				: 'good'
		},
	},
	watch: {
		issue(newValue, oldValue) {
			const { charMaximum, trimIssue } = this
			if (newValue.length > charMaximum) {
				this.issue = trimIssue(newValue)
			}
		}
	},
	methods: {
		set_active_tab(data) {
			if (data.tab) {
				if (!this.active_tab && data.tab == 'patients') return
				this.active_tab = data.tab
				setTimeout(() => {
					let max = Object.keys(this.tabs).reduce((a, key) => this.tabs[key].zIndex > a ? this.tabs[key].zIndex : a, 0)
					this.tabs[data.tab].zIndex = max + 1
				}, 125)
			}
		},
		restart() {
			this.$store.dispatch('launch_game', { game: this.game.current.game, prevent_init: true })
		},
		go_home() {
			this.$store.dispatch('get_current_user') // on rafraichi le user pour mettre à jour la progression
			// if (this.game.current.score) {
			// 	this.$store.dispatch('set_end_demo', { end_demo: true })
			// } else
				this.$store.dispatch('init')
		},
		closeFeedbackWindow() {
			this.openFeedbackWindow = false
		},
		enterFeedback() {
			this.openFeedbackWindow = true
		},
		trimIssue(issue) {
			const { charMaximum } = this
			return issue
				.trim()
				.substring(0, charMaximum)
		},
		async send_feedback() {
			const {
				charMinimum,
				game: {
					current: {
						game: {
							document
						} = {}
					} = {},
				},
				issue,
				trimIssue,
				user: { logged_user: { email, firstName, lastName } = {} } = {}
			} = this
			let to = process.env.EMAIL_FOR_DEVELOPERS
			if (!issue || issue.length < charMinimum) return
			const {
				'patient name':patientName,
				'scenario name':scenarioName
			} = document
			if (document) {
				const { game_issue_email } = document || {}
				if (process.env.NODE_ENV === 'production' && game_issue_email) to = game_issue_email
			}
			if (!to || !scenarioName) return
			const body = `
				SCENARIO: ${scenarioName}

				PATIENT NAME: ${patientName}

				ISSUE: ${trimIssue(issue)}
			`
			const variables = {
				input: {
					to,
					from: `"${firstName} ${lastName}" <${email}>`,
					subject: 'Issue reported for Level Up Doctor',
					body
				}
			}
			const result = await Apollo.mutate('SEND_EMAIL', variables)
			if (result) {
				this.openFeedbackWindow = false
				this.messageSent = true
				this.issue = ''
				this.charCounter = 0
				setTimeout(() => {
					this.messageSent = false
				}, 2500)
			}
		},
	},
	components: { Page }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.bad
	color #e96161
.good
	color green

.message-sent,
.feedback-window
	z-index 1000
	position absolute
	background-color white
	left 5%
	top 5%
	width 90%
	height 93%
	border-radius 10px
	padding 12px
	box-shadow 0 0 0 8px purple
	.char-counter
		position absolute
		left 20px
		bottom -13px
		background-color white
		padding 0 5px
		font-weight bold
	>textarea
		position absolute
		top 3%
		font-size 1.4em
		width 94%
		height 92%
		resize none
		border none
		outline none
		overflow auto
	.issue-title
		position absolute
		top -13px
		left 20px
		background-color white
		padding 0 5px
		color purple
		font-weight bold
	.close
		position absolute
		right 8px
		top -35px
		width (3 * 12px)
		height 32px
		background purple url(../../assets/img/close.svg) center center no-repeat
		background-size 16px auto
		border-top-left-radius 8px
		border-top-right-radius 8px
		cursor pointer
	.send-message
		position absolute
		height 30px
		bottom -18px
		right 8px
		cursor pointer
		text-align center
		width 50%
		color white
		font-size 1em
		padding 5px
		background-color #61789e
		border-radius 10px
		&:active
			transform translateY(2px)

#app.perf-normal, #app.perf-high
	.game-result-enter-active, .game-result-leave-active
		transition transform 0.25s easeOutQuart
		.bg
			transition opacity 0.25s easeOutQuart
			transition-delay 0.25s
	.game-result-enter, .game-result-leave-to
		transform translateY(-100px)
		opacity 0
		.bg
			opacity 0

#app.mobile, #app.tablet
	.game-result
		padding-right 0
		z-index 2
		align-items center
		justify-content center


#app.mobile
	.game-result
		flex center flex-start
		bottom -8px
		.content
			height 100%
			margin 0
			.main-title
				display none

.game-result
	position absolute
	left 0
	top 0
	right 0
	bottom 60px
	display flex
	align-items center
	justify-content flex-end
	padding-right 96px
	// background-color alpha(dark, 80%)
	.bg
		position absolute
		left 0
		top 0
		right 0
		bottom -60px
		background-color alpha(dark, 50%)
		opacity 1
	.content
		width 100%
		max-width 450px
		height calc(100% - 80px)
		max-height 700px
		margin-top 32px
		background-color dark
		border-radius 8px
		box-shadow 0 4px 8px alpha(dark, 50%)
		color dark
		> .main-title
			position absolute
			left 0
			top -32px
			height 40px
			background-color dark
			padding 0 24px
			border-top-left-radius 8px
			border-top-right-radius 8px
			line-height @height
			color #fff
		> .pages
			margin 40px 8px 0 8px
			height calc(100% - 48px)
			.page
				&.active
					transition-delay 0
					animation page_active 0.35s easeOutQuart forwards
		> .buttons
			height 64px
			// padding 0 0 8px 0
			background-color dark
			flex center center
			border-bottom-left-radius 8px
			border-bottom-right-radius 8px
			.button
				margin 0 8px
				height 40px
				width 104px
				border-radius 16px
				flex center center
				background #61789E center center no-repeat
				background-size 24px 24px
				cursor pointer
				color #fff
				text-align center
				text-transform uppercase
				line-height 16px
				font-size 1.4rem
				box-shadow inset 0 -2px alpha(#000, 25%)
				&.issue
					background-color red_error
				&.redo
					background-image url(../../assets/img/redo.svg)
				&.home
					background-image url(../../assets/img/home.svg)
				&:active
					transform translateY(2px)
					box-shadow none




@keyframes page_active
	0%
		transform translateY(0)
	50%
		transform translateY(-64px) translateX(-32px) rotate(-4deg)
	100%
		transform translateY(4px)

</style>
