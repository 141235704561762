<template>
	<div v-if="name" class="page" :class="'page-' + name">
		<div class="tab" v-hammer:tap="()=> set_active_tab()">
			<span v-if="name == 'result'">{{ $t('result') }}</span>
			<span v-if="name == 'recap'">{{ $t('recap') }}</span>
			<span v-if="name == 'comment'">{{ $t('comment') }}</span>
		</div>
		<div class="page-content">
			<component :is="'page-' + name" :user="user" />
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PageResult from '@/components/game/game-result/pages/page-result'
import PageRecap from '@/components/game/game-result/pages/page-recap'
import PageComment from '@/components/game/game-result/pages/page-comment'

export default {
	name: 'page',
	data() {
		return {

		}
	},
	props: {
		name: { default: false }
	},
	computed: {
		...mapGetters([ 'game' ]),
		...mapState({
			user: state => state.user.logged_user,
		})
	},
	methods: {
		set_active_tab() {
			this.$emit('change_tab', { tab: this.name })
		},
	},
	components: { PageResult, PageRecap, PageComment }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


.page
	position absolute
	left 0
	top 0
	right 0
	height 100%
	background-color #fff
	box-shadow 0 0 4px 1px alpha(#000, 50%)
	transition 0.1s easeOutQuart
	&.page-result
		box-shadow inset 0 4px 0 0 blue, 0 0 4px alpha(#000, 50%)
		.tab
			left 0
			background-color blue
		>>> .page-content .page-title
			color blue
	&.page-recap
		box-shadow inset 0 4px 0 0 yellow, 0 0 4px alpha(#000, 50%)
		.tab
			left 50%
			transform translateX(-50%)
			background-color yellow
		>>> .page-content
			.page-title
				color yellow
			.footer
				border-color yellow
				.footer-title
					color yellow
	&.page-comment
		box-shadow inset 0 4px 0 0 red, 0 0 4px alpha(#000, 50%)
		.tab
			right 0
			background-color red
		>>> .page-content
			.page-title
				color red
			.footer
				border-color #c65555
	&.active
		animation page_active 0.35s easeOutQuart forwards
	.tab
		position absolute
		top -32px
		width 33%
		height 36px
		background-color #666
		border-top-left-radius 8px
		border-top-right-radius 8px
		line-height 32px
		white-space nowrap
		cursor pointer
		color #fff
		font-weight 700
		font-size 1.4rem
		text-align center
	.page-content
		height 100%
		width 100%
		padding 8px 16px
		>>> .page-title
			height 48px
			text-align center
			white-space nowrap
			text-transform uppercase
			line-height @height
			font-weight 700
			font-size 2rem

</style>
