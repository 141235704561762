<template>
	<div class="tuto-notification" :class="[ 'x-' + tuto_step.params.position.x, 'y-' + tuto_step.params.position.y ]" v-hammer:tap="()=> next_step()">
		<div class="icon">
			<img src="@/assets/img/doc-tuto.svg">
		</div>
		<div class="text">{{ tuto_step.text }}</div>
		<div class="hint">{{ $t('click_here_to_continue') }}</div>
	</div>
</template>

<script>
export default {
	name: 'tuto-notification',
	data() {
		return {

		}
	},
	props: [ 'tuto_step' ],
	methods: {
		next_step() {
			this.$store.dispatch('next_tuto_step')
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.perf-normal, #app.perf-high
	.tuto-notification-enter-active, .tuto-notification-leave-active
		transition 0.35s easeOutQuart
	.tuto-notification-enter, .tuto-notification-leave-to
		transform translateX(150%)


#app.mobile
	.tuto-notification
		height 140px
		width 300px
		.icon
			height 140px
			width 140px
			left -(@width / 2)
		.text
			padding-left 88px

.tuto-notification
	position absolute
	height 160px
	width 320px
	background-color red
	box-shadow inset 0 0 0 4px red_light
	&.x-right
		right 0
	&.y-top
		top 40px
	&.y-center
		top calc(50% - 100px)
	.icon
		position absolute
		height 160px
		width 160px
		top 0
		left -(@width / 2)
		background-color yellow
		border-radius 50%
		box-shadow 0 0 8px yellow
		> img
			position absolute
			left 50%
			bottom 0
			width 60%
			height auto
			transform translateX(-50%)
	.text
		height 100%
		padding 0 24px 16px 124px
		display flex
		align-items center
		justify-content center
		flex-direction column
		color #fff
		text-align center
		line-height 1.1
	.hint
		position absolute
		right 16px
		bottom 12px
		color #fff
		font-size 1rem
		opacity 0.5
		animation blink 2.5s infinite




</style>
