<template>
	<div class="pending-comments" :class="{ visible: visible }">
		<div class="bg"></div>
		<div class="scroll" v-scrollbar>
			<div>
				<div class="pending-comments-content">
					<!-- <p class="title">{{ $t('comment_review') }} <span class="nb">{{ game.pending_comments.length }}</span></p> -->
					<p class="description">{{ $t('all_comments') }}</p>
					<p class="description"><span class="icon approved"></span>{{ $t('approving_comment') }}</p>
					<p class="description"><span class="icon refused"></span>{{ $t('deem_comment') }}</p>
					<p class="description"><span class="icon pending"></span>{{ $t('awaiting_comment') }}</p>

					<div v-if="game.pending_comments.length" class="comments">
						<div v-for="(group, property) in getGroupedComments" class="group" :class="{ expanded: expanded.includes(property) }">
							<div class="group-title" v-hammer:tap="() => toggle_group(property)">
								<span class="text">
									{{ getGroupName({ property }) }}
									<span class="nb">{{ group.length }}</span>
								</span>
								<span class="expand-button"><span class="text text-expand">Expand</span><span class="text text-close">Close</span></span>
							</div>
							<div class="group-content">

								<div v-for="comment in group" v-if="comment && comment.clientUser" :key="comment.id" class="thread" :class="{ loading: is_loading(comment) }">
									<div class="thread-title">
										<span v-if="comment.commentableElementType === 'Client::Comment'"><span class="icon responding-to"></span>{{ $t('responding_to') }}</span>
										<span v-if="comment.commentableElementType === 'Client::Game'"><span class="icon new-thread"></span>{{ $t('new_thread') }}</span>

										<span v-if="comment.commentableElement.document && comment.commentableElement.document['patient name']" class="patient-name"> (patient name : {{ comment.commentableElement.document['patient name'] }})</span>
									</div>
									<div v-if="comment.commentableElementType === 'Client::Comment'" class="comment">
										<div class="left">
											<ul v-if="isPreviousUser({ comment })">
												<li class="author">{{ getPreviousUsername({ comment }) }}</li>
												<li class="date">{{ ago(getPreviousCreatedAt({ comment })) }}</li>
											</ul>
										</div>
										<div class="right">
											<div class="text">
												<ui-text-content v-if="getCommentableContent({ comment })" class="previous left" :class="getIcon({ comment })" :html="getCommentableContent({ comment })" />
											</div>
										</div>
									</div>
									<div class="comment">
										<div class="left">
											<ul>
												<li v-if="comment.clientUser.firstName || comment.clientUser.lastName" class="author">{{ comment.clientUser.firstName + ' ' + comment.clientUser.lastName }}</li>
												<li class="date">{{ ago(comment.createdAt) }}</li>
											</ul>
										</div>
										<div class="right">
											<div class="text">
												<ui-text-content class="icon-comment" :html="comment.content" />
											</div>
										</div>
										<div class="actions">
											<div class="action approve" v-hammer:tap="() => approve(comment)">{{ $t('approve') }}</div>
											<div class="action refuse" v-hammer:tap="() => refuse(comment)">{{ $t('refuser') }}</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
					<div class="no-comments" v-else>
						<div class="icon"></div>
						<p>{{ $t('no_comment_review') }}</p>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="close" title="Close" v-hammer:tap="() => $emit('close')"></div> -->
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatDistanceToNow } from 'date-fns'
import UiTextContent from '@/components/ui/ui-text-content'
import { deepPropertyGrouping } from '@/js/utils/utils'
import { frCA, enUS } from 'date-fns/locale'
import { LANGUAGE } from '@/js/factory/language'

export default {
	name: 'pending-comments',
	data() {
		return {
			visible: false,
			expanded: [  ],
			loadings: [  ]
		}
	},
	computed: {
		...mapGetters([ 'game' ]),
		getGroupedComments() {
			const object = this.game.pending_comments
			const property = 'scenario name'
			return deepPropertyGrouping({ object, property })
		},
	},
	methods: {
		getPreviousCreatedAt({ comment: { commentableElement: { clientUser: { createdAt = null } = {} } = {}} = {} }) {
			return createdAt
		},
		isPreviousUser({ comment: { commentableElement: { clientUser = null } = {}} = {} }) {
			return !!clientUser
		},
		getPreviousUsername({ comment: { commentableElement: { clientUser: { firstName = '', lastName = '' } } = {}} = {} }) {
			return `${firstName} ${lastName}`
		},
		getGroupName({ property }) {
			return property
		},
		getCommentableContent({
			comment: { commentableElement: { document: {
				'scenario name': scenarioName = '',
				'patient name': patientName = ''
			} = {}, content = null } = {} } = {}
		}) {
			return scenarioName
				? `Patient name: ${patientName}`
				: content
		},
		getIcon({ comment: { commentableElementType } = {} }) {
			return `icon-${
				commentableElementType
					.toLowerCase()
					.replace('client::', '')
			}`
		},
		ago(date) {
			const locale = LANGUAGE.getData({ definition: 'Language' }) === 'fr' ? frCA : enUS
			return formatDistanceToNow(new Date(date), { addSuffix: true, locale })
		},
		is_loading(comment) {
			return this.loadings.includes(comment.id)
		},
		toggle_group(group) {
			if (this.expanded.includes(group))
				this.expanded = this.expanded.filter((g) => g !== group)
			else
				this.expanded.push(group)
		},
		async approve(comment) {
			if (!this.is_loading(comment)) {
				this.loadings.push(comment.id)
				let r = await this.$store.dispatch('approve_comment', { id: comment.id })
				// setTimeout(() => {
					let index = this.loadings.indexOf(comment.id)
					if (index > -1) this.loadings.splice(index, 1)
				// }, 1500)
			}
		},
		async refuse(comment) {
			if (!this.is_loading(comment)) {
				this.loadings.push(comment.id)
				let r = await this.$store.dispatch('refuse_comment', { id: comment.id })
				// setTimeout(() => {
					let index = this.loadings.indexOf(comment.id)
					if (index > -1) this.loadings.splice(index, 1)
				// }, 1500)
			}
		},
	},
	components: { UiTextContent }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile
	.pending-comments
		// right 8px
		// top 8px
		// height calc(100% - 16px)
		// width calc(100% - 16px)
		border-radius 8px
		.scroll
			.pending-comments-content
				padding 16px
				// > .description
				// 	display none
				.comments
					.group
						.group-title
							.text
								display block
								width 100%
								padding-right 40px
								.nb
									position absolute
									right 0
									top 50%
									transform translateY(-50%)
							.expand-button
								display none
						.group-content
							.thread
								.comment
									flex-direction column
									padding 8px
									> .left, > .right, > .actions
										width 100%
										padding 0
									> .left
										ul
											margin 0
											list-style-type none
									> .right
										&:before
											display none
									> .actions
										margin-top 8px
										flex center center
										.action
											border-radius 8px
											margin 0
											width calc(50% - 2px)
											&:first-child
												margin 0 2px 0 0
											&:last-child
												margin 0 0 0 2px

.pending-comments
	// position absolute
	// right 32px
	// top 32px
	// height calc(100% - 92px)
	// width calc(100% - 64px)
	width 100%
	height 100%
	// background-color #FCFDFF
	// border 2px solid dark
	text-align left
	// border-top-left-radius 16px
	// border-top-right-radius 16px
	font-size 1.4rem
	transition 0.25s easeOutQuart
	transform-origin top right
	&.comments-enter, &.comments-leave-to
		transform scale(0)
		opacity 0
	.scroll
		height 100%
		width 100%
		.pending-comments-content
			padding 24px
			color dark
			.title
				flex flex-start center
				padding 0 0 16px 0
				margin 0 0 16px 0
				font-size 2rem
				border-bottom 2px solid dark
				line-height 24px
				white-space nowrap
				text-transform uppercase
				.nb
					display inline-block
					width 40px
					height 40px
					margin 0 0 0 16px
					background url(../../assets/img/comments/nb-comments-new.svg) center center no-repeat
					background-size contain
					line-height (@height + 0px)
					text-align center
					color #fff
					// font-size 1.6rem
			.description
				display block
				margin 0 0 8px 0
				// color #ccc
				line-height 18px
				flex flex-start flex-start
				.icon
					margin 2px 8px 0 0
					width 14px
					height 14px
					flex-shrink 0
					background center center no-repeat
					background-size contain
					&.approved
						background-image url(../../assets/img/comments/approved.svg)
					&.refused
						background-image url(../../assets/img/comments/refused.svg)
					&.pending
						background-image url(../../assets/img/comments/pending.svg)
			.comments
				padding 16px 0 0 0
				.group
					margin 0 0 16px 0
					border 2px solid #484C57
					background-color #484C57
					border-radius 16px
					box-shadow 0 4px 0px alpha(#000, 30%)
					color #fff
					&.expanded
						background-color #F9F9F9
						color #484C57
						.group-title
							border-radius 0
							border-top-left-radius 16px
							border-top-right-radius 16px
							&:hover
								background-color transparent
								.expand-button
									background-color alpha(#000, 10%)
							.expand-button
								box-shadow 0 0 0 1px @color
								&:after
									transform rotate(-180deg)
									border-color @color transparent transparent transparent
								.text-expand
									display none
								.text-close
									display block

						.group-content
							display block
					.group-title
						flex flex-start center
						height 48px
						padding 16px
						line-height 16px
						cursor pointer
						border-radius 16px
						&:after
							content ''
							position absolute
							left 8px
							right 8px
							bottom 0
							height 1px
							background-color #484C57
						&:hover
							background-color darken(#484C57, 10%)
						.nb
							display inline-block
							width 32px
							height 32px
							margin 0 0 0 8px
							background url(../../assets/img/comments/nb-comments-new.svg) center center no-repeat
							background-size contain
							line-height (@height + 0px)
							text-align center
							color #fff
						.expand-button
							position absolute
							right 8px
							top 8px
							height 32px
							padding 0 12px
							flex center center
							line-height @height
							border-radius 8px
							box-shadow 0 0 0 1px #fff
							transition 0.25s easeOutQuart
							&:after
								content ''
								margin 0 0 0 8px
								width 0
								height 0
								border-style solid
								border-width 12px 8px 0 8px
								border-color #fff transparent transparent transparent
								transition 0.25s easeOutQuart
							.text-close
								display none

					.group-content
						display none
						padding 8px
						.thread
							margin 0 0 8px 0
							padding 8px
							border-radius 8px
							background-color #D1D7E8
							&:last-child
								margin 0
							&.loading
								&:after
									content ''
									border-radius 8px
									absolute 0
									background alpha(dark, 50%) url(../../assets/img/button-loader.svg) center center no-repeat
									background-size 48px auto
									cursor wait
							.thread-title
								padding 0 8px 8px 8px
								.icon
									float left
									height 16px
									width 16px
									margin 0 8px 0 0
									background center center no-repeat
									background-size contain
									&.new-thread
										background-image url(../../assets/img/comments/new-thread.svg)
									&.responding-to
										background-image url(../../assets/img/comments/responding-to.svg)
							.comment
								flex flex-start flex-start
								line-height 22px
								&:last-child
									background-color #F6F8FF
									border-radius 8px
								> .left
									width 200px
									flex-shrink 0
									padding 16px
									ul
										margin-left 12px
										.date
											list-style-type none
											color #666
											font-size 1.1rem
											text-transform uppercase
								> .right
									width 100%
									padding 16px
									&:before
										content ''
										position absolute
										left 0
										top 16px
										height calc(100% - 32px)
										border-left 1px solid #484C57
								> .actions
									width 200px
									flex-shrink 0
									padding 8px
									.action
										width 100%
										height 40px
										cursor pointer
										line-height @height
										color #fff
										text-align center
										text-transform uppercase
										&.approve
											margin 0 0 2px 0
											border-top-left-radius 8px
											border-top-right-radius 8px
											background-color #7FAE51
											&:hover
												background-color lighten(#7FAE51, 10%)
										&.refuse
											border-bottom-left-radius 8px
											border-bottom-right-radius 8px
											background-color #D64348
											&:hover
												background-color lighten(#D64348, 10%)
			.no-comments
				padding 48px 0 24px 0
				width 100%
				flex center center
				flex-direction column
				text-align center
				text-transform uppercase
				color #999
				font-size 2rem
				line-height 1em
				.icon
					width 48px
					height 48px
					margin 0 0 8px 0
					background url(../../assets/img/check-circle.svg) center center no-repeat
					background-size contain
					opacity 0.5
				p
					width 100%
	.close
		position absolute
		right 16px
		top 16px
		width 32px
		height 32px
		background #fff url(../../assets/img/close-dark.svg) center center no-repeat
		border-radius 50%
		box-shadow 0 0 0 2px #fff
		background-size contain
		cursor pointer


</style>
