<template>
	<div class="bottom-bar" :class="{ tuto: tuto }">

		<!-- Answer question -->
		<ui-button v-if="game.current.step.layout == 'trivia' && !game.ui.active.patient_report_visible" class="button bounce right" key="1" v-hammer:tap="()=> answer()" :loading="app.loading" :inactive="!game.ui.active.selected_answers.length > 0">{{ $t('submit') }}</ui-button>

		<!-- Show patient report -->
		<ui-button v-if="game.current.step.layout == 'trivia' && !game.ui.active.patient_report_visible && app.device != 'desktop'" class="button left" key="2" v-hammer:tap="()=> set_active_patient_report(true)">{{ $t('summary') }}</ui-button>

		<!-- Close patient report -->
		<ui-button v-if="game.current.step.layout == 'presentation'" class="button bounce right" :loading="app.loading" key="3" v-hammer:tap="()=> complete_patient_report()">{{ $t('done') }}</ui-button>

		<!-- Hide patient report -->
		<ui-button v-if="game.current.step.layout != 'presentation' && game.ui.active.patient_report_visible" class="button bounce right" key="4" v-hammer:tap="()=> set_active_patient_report(false)">{{ $t('done') }}</ui-button>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'bottom-bar',
	data() {
		return {
			clicked: false
		}
	},
	computed: {
		...mapGetters([ 'app', 'game' ]),
	},
	props: [ 'tuto' ],
	methods: {
		set_active_patient_report(active) {
			if (!this.clicked) {
				this.click()
				this.$store.dispatch('set_active_patient_report', { active: active })
			}
		},
		complete_patient_report(active) {
			if (!this.clicked) {
				this.click()
				this.$store.dispatch('complete_current_step')
				if (this.tuto && !this.game.tuto.done && this.game.tuto.current_step == 5) this.$store.dispatch('next_tuto_step')
			}
		},
		answer() {
			if (!this.clicked) {
				this.click()
				if (this.game.ui.active.selected_answers.length > 0) {
					this.$store.dispatch('send_answer')
					this.$store.dispatch('set_active_answer', { answer: false, selected_answers: [] })
				}
			}
		},
		click() {
			this.clicked = true
			setTimeout(() => {
				this.clicked = false
			}, 250)
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

#app.desktop, #app.tablet
	.bottom-bar
		.ui-button
			transform translate(16px, -156px)
			&.right
				// transform translate(-16px, -156px)
				transform translate(-16px, -180px)
				>>> .button
					background-color red
					background linear-gradient(to top, darken(red, 5%) 0%, red 100%)
					&:hover, &:active
						background linear-gradient(to bottom, darken(red, 5%) 0%, red 100%)
			&.inactive
				>>> .button
					opacity 1
					background #ccc
					&:hover, &:active
						background #ccc
			>>> .button
				height 96px
				width 96px
				border-radius 50%
				padding 0
				box-shadow 0 0 0 4px dark

.bottom-bar
	position absolute
	left 0
	right 0
	bottom 0
	width 100%
	height 64px
	padding 12px 24px
	background-color dark
	&.tuto
		.ui-button.left
			display none
		.ui-button.right
			z-index 2
	.ui-button
		&.left
			float left
		&.right
			float right



</style>
