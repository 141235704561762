<template>
	<div class="page-content-recap">
		<div class="page-title">
			<span v-if="current_step" class="question-title">{{ $t('question') }} {{ current_index + 1 }} <span class="star" :class="{ empty: current_step.score <= 0.25, half: current_step.score > 0.25 && current_step.score <= 0.75, full: current_step.score > 0.75 }"></span></span>
			<span v-else>{{ $t('scenario_timeline') }}</span>

			<div v-if="current_step" class="arrow left" :class="{ inactive: current_index === 0 }" v-hammer:tap="() => set_current_index(current_index-1)"></div>
			<div v-if="current_step" class="arrow right" :class="{ inactive: current_index === questions.length - 1 }" v-hammer:tap="() => set_current_index(current_index+1)"></div>
		</div>

		<div class="content-timeline">
			<div class="scroll" v-scrollbar:scrollArrows>
				<div>
					<div class="questions">
						<div class="bg"></div>
						<div v-for="(question, index) in questions" class="question" :key="question.id">
							<div class="label"><span class="arrow"></span>{{ $t('question') }} {{ index + 1 }}</div>
							<div class="star"
								:class="{ empty: question.score < 0.25, half: question.score >= 0.25 && question.score <= 0.75, full: question.score > 0.75 }"
								v-hammer:tap="() => set_current_index(index)"
							></div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="current_step" class="content-detail">
			<div class="side">
				<div class="close" v-hammer:tap="() => close()"></div>
			</div>
			<div class="scroll" v-scrollbar:scrollArrows>
				<div>
					<div class="box">
						<p class="box-title">{{ $t('question') }}</p>
						<div class="box-content">
							<ui-text-content :html="current_question_html" />
						</div>
					</div>
					<div class="box">
						<p class="box-title">{{ $t('answer') }}</p>
						<div class="box-content">
							<div class="answer" v-for="answer in answers" :key="answer.id" :class="{ selected: answer.selected }">
								<div class="checkbox"></div>
								<ui-text-content :html="answer.html" />
							</div>
						</div>
					</div>
					<div class="box">
						<p class="box-title">{{ $t('about_your_answer') }}</p>
						<div class="box-content">
							<ui-text-content :html="learn_more" />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="app.loading" class="content-loading"></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getValueOnPath } from '@/js/utils/utils'
import UiTextContent from '@/components/ui/ui-text-content'

export default {
	name: 'page-recap',
	data() {
		return {
			current_index: -1
		}
	},
	computed: {
		...mapGetters([ 'app', 'game' ]),
		questions() {
			return this.game.current.marks.filter((m) => { return m.score !== null })
		},
		current_step() {
			return this.questions[this.current_index]
		},
		current_question() {
			const questions = getValueOnPath(this.current_step, 'step.questions')
			if (questions && questions[0]) return questions[0]
			return null
		},
		current_question_html() {
			if (this.current_question && this.current_question.contentTexts) {
				const text = this.current_question.contentTexts.find((t) => { return t.tags && t.tags.includes('question') })
				if (text && text.html) return text.html
			}
			return ''
		},
		answers() {
			let answers = []
			this.current_question.answers.forEach((a) => {
				if (a && a.contentTexts) {
					const text = a.contentTexts.find((t) => { return t && t.tags.includes('answer') })
					if (text && text.html) {
						let selected = false
						if (this.current_step.clientLogs && this.current_step.clientLogs.data && this.current_step.clientLogs.data[0].answers && this.current_step.clientLogs.data[0].answers[0])
							selected = this.current_step.clientLogs.data[0].answers[0] === a.id
						answers.push({ id: a.id, html: text.html, selected })
					}
				}
			})

			return answers
		},
		learn_more() {
			let html = ''
			if (this.current_step) {
				let mark_index = this.game.current.marks.findIndex((m) => { return m.step.id == this.current_step.step.id })
				if (mark_index > -1) {
					let texts = []
					for (let i in this.game.current.marks) {
						let m = this.game.current.marks[i]
						if (i > mark_index) {
							if (m.step.layout == 'result') texts = m.step.contentTexts
							if (m.step.layout == 'trivia') break
						}
					}
					let mores = texts.filter((t) => { return t.tags.includes('result_description') || t.tags.includes('result_learn_more') })
					for (let i in mores) {
						if (mores[i].html) html += mores[i].html
					}
				}

			}
			return html
		}
	},
	methods: {
		set_current_index(index) {
			if (index >= 0 && index < this.questions.length) {
				this.current_index = index
			}
		},
		close() {
			this.current_index = -1
		}
	},
	mounted() {
		this.$store.dispatch('get_end_of_scenario')
	},
	components: { UiTextContent }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.page-content-recap
	height 100%
	.page-title
		.question-title
			display inline-block
			height 24px
			width 140px
			line-height 24px
			transform translateX(-16px)
			.star
				position absolute
				right -28px
				top 0
				width 24px
				height 24px
				transform translateY(-1px)
		> .arrow
			position absolute
			top calc(50% - 12px)
			width 24px
			height 24px
			width 0
			height 0
			border-style solid
			cursor pointer
			&.left
				left 12%
				border-width 12px 24px 12px 0
				border-color transparent yellow transparent transparent
			&.right
				right 12%
				border-width 12px 0 12px 24px
				border-color transparent transparent transparent yellow
			&.inactive
				cursor not-allowed
				opacity 0.25
	.content-timeline, .content-detail
		height calc(100% - 48px)
		.scroll
			height 100%


.content-timeline
	.questions
		flex space-between flex-start
		flex-direction column
		width 280px
		padding round(116px / 2) 0 0 0
		margin round(116px / 2) auto 0 auto
		.bg
			absolute 0
			bottom round(116px + 116px / 2)
			background url(../../../../assets/img/game-result/path.svg) top left no-repeat
			background-size 100% auto
			opacity 0.25
		.question
			width 100%
			height 116px
			// box-shadow inset 0 0 0 2px blue
			transform translateY(-100%)
			text-transform uppercase
			font-size 1.4rem
			color #6F7784
			color dark
			&:nth-child(even)
				.star
					left 0
					margin-left -16px
				.label
					left 32px
					.arrow
						transform scaleX(-1)
						animation arrow_reverse 1s easeOutQuart infinite alternate
			&:nth-child(odd)
				.star
					right 0
					margin-right -16px
				.label
					right 32px
					text-align right
					flex-direction row-reverse
					.arrow
						animation arrow 1s easeOutQuart infinite alternate
			.label
				position absolute
				top calc(50% - 20px)
				height 40px
				padding 0 8px
				color yellow
				flex center center
				line-height @height
				white-space nowrap
				text-transform uppercase
				font-weight 700
				font-size 2rem
				// color dark
				cursor pointer
			.arrow
				display inline-block
				height 16px
				width 24px
				background url(../../../../assets/img/game-result/chevron-right.svg) right center no-repeat
				background-size 16px 16px
			.star
				position absolute
				top 50%
				transform translateY(-50%)
				cursor pointer



.star
	display inline-block
	width 48px
	height 48px
	background center center no-repeat
	background-size 100% auto
	&.empty
		background-image url(../../../../assets/img/game-result/star-empty.svg)
	&.half
		background-image url(../../../../assets/img/game-result/star-half.svg)
	&.full
		background-image url(../../../../assets/img/game-result/star-full.svg)


.content-detail
	absolute 0
	margin-top 48px
	background-color #fff
	.side
		position absolute
		left 0
		top 0
		padding 8px 0 0 0
		width 40px
		height 100%
		border-right 2px solid yellow
		&:before
			content ''
			position absolute
			bottom 0
			right -6px
			width 10px
			height 10px
			border-radius 50%
			background-color #fff
			box-shadow inset 0 0 0 2px yellow
		.close
			position absolute
			right 0
			top 0
			width 32px
			height 48px
			border-top-left-radius 8px
			border-bottom-left-radius 8px
			background purple url(../../../../assets/img/close.svg) center center no-repeat
			background-size 16px auto
			cursor pointer
	.scroll
		width calc(100% - 64px)
		margin-left 64px
		.box
			margin 0 0 40px 0
			.box-title
				margin 0 0 4px 0
				flex flex-start center
				// background-color #eee
				// padding 4px 8px
				// border-radius 4px
				line-height 24px
				text-transform uppercase
				font-size 1.4rem
				font-weight 700
				&:before
					content ''
					display inline-block
					width 12px
					height 12px
					margin 0 8px 0 0
					background-color yellow
			.box-content
				padding 0 0 0 20px
				.answer
					padding 0 0 0 28px
					margin 0 0 8px 0
					border-radius 4px
					background url(../../../../assets/img/circle.svg) left top 1px no-repeat
					background-size 20px 20px
					opacity 0.5
					&.selected
						opacity 1
						background-image url(../../../../assets/img/check-circle.svg)


.content-loading
	absolute 0
	background #fff url(../../../../assets/img/button-loader-dark.svg) center center no-repeat
	background-size 64px auto



@keyframes arrow
	0%
		opacity 1
		transform translateX(8px)
	100%
		opacity 0.5
		transform translateX(0px)

@keyframes arrow_reverse
	0%
		opacity 1
		transform translateX(-8px) scaleX(-1)
	100%
		opacity 0.5
		transform translateX(0px) scaleX(-1)


</style>
