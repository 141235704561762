<template>
	<div class="settings">
		<div class="bg" v-hammer:tap="()=> close()"></div>
		<div class="content">

			<div class="main-title">{{ $t('settings') }}</div>

			<div class="item">
				<p class="label">{{ $t('animation_quality') }}</p>
				<div class="tabs nb-3">
					<div v-for="quality in qualities" class="tab" :class="{ active: app.performance == quality.value }" v-hammer:tap="()=> set_quality(quality)">{{ quality.label }}</div>
				</div>
			</div>

			<div class="item">
				<p class="label">{{ $t('language') }}</p>
				<div class="tabs nb-2">
					<div class="tab" :class="{ active: lang === 'en' }" v-hammer:tap="() => set_lang('en')">EN</div>
					<div class="tab" :class="{ active: lang === 'fr' }" v-hammer:tap="() => set_lang('fr')">FR</div>
				</div>
				<p v-show="initial_lang !== lang" class="restart">{{ $t('restart_game') }}</p>
			</div>

			<div class="buttons">
				<ui-button color="red" v-hammer:tap="() => submit()">OK</ui-button>
			</div>

			<div class="close" v-hammer:tap="() => close()"></div>

		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { LANGUAGE } from '@/js/factory/language'
import UiTextContent from '@/components/ui/ui-text-content'
import Vue from 'vue'

export default {
	name: 'settings',
	data() {
		return {
			qualities: [
				{ value: 'low', label: Vue.i18n.translate('standard_quality')},
				{ value: 'medium', label: Vue.i18n.translate('high_quality') },
				{ value: 'high', label: Vue.i18n.translate('ultra_quality') }
			],
			initial_lang: null,
			lang: null
		}
	},
	computed: {
		...mapGetters([ 'app' ])
	},
	methods: {
		set_quality(quality) {
			this.$store.dispatch('set_performance', { performance: quality.value })
		},
		set_lang(lang) {
			this.lang = lang
		},
		reset() {
			this.$store.dispatch('reset_progression')
			this.close()
		},
		submit() {
			if (this.lang !== this.initial_lang) {
				LANGUAGE.setData({ definition: 'Language', data: this.lang })
				location.reload()
			}
			this.close()
		},
		close() {
			this.$store.dispatch('set_settings', { settings: false })
		}
	},
	created() {
		this.quality = this.app.performance
		this.initial_lang = LANGUAGE.getData({ definition: 'Language' })
		this.lang = this.initial_lang
	},
	components: { UiTextContent }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'


.settings
	&.settings-enter-active, &.settings-leave-active
		transition 0.25s easeOutQuart
	&.settings-enter, &.settings-leave-to
		opacity 0
		.content
			transform translateY(-20px)


.settings
	position absolute
	left 0
	top 0
	right 0
	bottom 0
	display flex
	align-items center
	justify-content center
	.bg
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		background-color alpha(#000, 50%)
	.content
		width calc(100% - 40px)
		max-width 400px
		padding 8px 0
		background-color #f5f5f5
		border-radius 8px
		box-shadow 0px 10px 6px alpha(#000, 30%)
		transition 0.25s easeOutQuart

		.main-title
			padding 16px
			// margin 0 0 24px 0
			text-align center
			text-transform uppercase
			font-weight 500
			font-size 2rem
		.item
			background-color #eee
			padding 16px
			margin 0 0 8px 0
			text-align center
			.label
				display block
				text-align left
				height 20px
				margin 0 0 4px 0
				text-transform uppercase
				font-size 1.2rem
				line-height 16px
				white-space nowrap
				color dark_light
			.tabs
				display flex
				justify-content center
				align-items center
				height 32px
				box-shadow inset 0 0 0 2px red
				border-radius 32px
				overflow hidden
				mask-image radial-gradient(white, black)
				&.nb-2 .tab
					width 50%
				&.nb-3 .tab
					width 33.3333%
				.tab
					display inline-block
					height 32px
					box-shadow inset 2px 0 0 0px red
					text-align center
					line-height @height
					cursor pointer
					&.active
						background-color red
						color #fff
			.restart
				padding 0 16px
				margin 8px 0 0 0
				color #f44336
				line-height 1.2em
				text-align left
		.buttons
			padding 16px
			text-align center
			.ui-button
				margin 0 4px
			>>> .reset .button
				color #f44336
				background-color lighten(@color, 85%)
				&:hover
					background-color lighten(@color, 75%)
		.close
			position absolute
			right -8px
			top -8px
			width 40px
			height 40px
			background dark url(../assets/img/close.svg) center center no-repeat
			background-size 16px auto
			border-radius 50%
			border 4px solid #f5f5f5
			cursor pointer
			&:active
				transform translateY(1px)


</style>
