<template>
	<div class="administrator-access">
		<div class="bg"></div>
		<div class="content">
			
			<div class="tabs">
				<div class="tab" :class="{ active: tab === 'comments' }" v-hammer:tap="() => tab = 'comments'">
					<span>{{ $t('comment_review') }}</span>
					<span v-if="game.pending_comments.length" class="nb">{{ game.pending_comments.length }}</span>
				</div>
				<div class="tab" :class="{ active: tab === 'papers' }" v-hammer:tap="() => tab = 'papers'">
					<span>{{ $t('research_papers_licences') }}</span>
					<span v-if="licences_warning" class="warning"></span>
				</div>
			</div>

			<div class="tab-content">
				<pending-comments v-show="tab === 'comments'" />
				<research-papers-licences v-show="tab === 'papers'" />
			</div>

		</div>
		<div class="close" title="Close" v-hammer:tap="() => $emit('close')"></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import PendingComments from '@/components/administrator-access/pending-comments'
import ResearchPapersLicences from '@/components/administrator-access/research-papers-licences'

export default {
	name: 'administrator-access',
	data() {
		return {
			tab: 'comments'
		}
	},
	computed: {
		...mapGetters([ 'app', 'game' ]),
		licences_warning() {
			return this.app.research_papers_licences
				.find((p) => p && p.accessibleSettings && !p.accessibleSettings.unlimitedAccess && p.accessibleSettings.licenseFor - p.accessibleSettings.accessCount < 10)
				? true : false
		}
	},
	methods: {
		
	},
	components: { PendingComments, ResearchPapersLicences }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'



.administrator-access
	absolute 0
	flex flex-start flex-end
	padding 16px
	.bg
		absolute 0
		background-color alpha(#000, 50%)
	.content
		height calc(100% - 16px)
		width 100%
		.tabs
			width 100%
			height 48px
			text-align left
			.tab
				display inline-block
				height calc(100% + 12px)
				padding 0 24px
				margin 0 8px 0 0
				background-color #ccc
				border 2px solid dark
				border-bottom none
				line-height 50px
				border-top-left-radius 16px
				border-top-right-radius 16px
				text-transform uppercase
				color dark
				cursor pointer
				&.active
					background-color #FCFDFF
					&:after
						content ''
						position absolute
						left 0
						right 0
						bottom 0
						height 16px
						background-color @background-color
						z-index 2
				.nb
					display inline-block
					float right
					width 28px
					height 28px
					margin 8px 0 0 16px
					background url(../assets/img/comments/nb-comments-new.svg) center center no-repeat
					background-size contain
					line-height (@height + 2px)
					text-align center
					color #fff
				.warning
					display inline-block
					float right
					width 28px
					height 28px
					margin 8px 0 0 16px
					background url(../assets/img/administrator-access/red-file-dark-border.svg) center center no-repeat
					background-size contain
					line-height (@height + 2px)
					text-align center
					color #fff
		.tab-content
			width 100%
			height calc(100% - 32px)
			background-color #FCFDFF
			border 2px solid dark
			border-bottom none
			border-top-left-radius 16px
			border-top-right-radius 16px

	.close
		position absolute
		right 32px
		top 32px
		width 32px
		height 32px
		background #fff url(../assets/img/close-dark.svg) center center no-repeat
		border-radius 50%
		box-shadow inset 0 0 0 2px dark
		background-size contain
		cursor pointer


// @media (max-width: 570px)
#app.mobile
	.administrator-access
		// padding-top 16px
		align-items flex-end
		.content
			height calc(100% - 40px)
			.tabs
				flex flex-start flex-start
				.tab
					width calc(50% - 4px)
					padding 0 8px 12px 8px
					line-height 16px
					text-align center
					flex center center
					&:first-child
						margin 0 4px 0 0
					&:last-child
						margin 0 0 0 4px
		.close
			top 8px
			right 8px
	

</style>
