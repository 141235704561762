<template>
	<transition name="slide">
		<div v-show="!app.online" class="ui-offline-bar">
			<div class="bar">
				<p><strong>{{ $t('offline') }}</strong></p>
				<p>{{ $t('waiting_connection') }}</p>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import {manageEventListeners} from "@/js/managers/manageEventListeners"

export default {
	name: 'ui-offline-bar',
	data() {
		return {
			manageEventListeners: manageEventListeners()
		}
	},
	computed: {
		...mapGetters([ 'app' ])
	},
	mounted() {
		let el = this.$el
		this.manageEventListeners.add(window, 'online', () => {
			this.$store.dispatch('edit_online', { online: true })
		})
		this.manageEventListeners.add(window, 'offline', () => {
			this.$store.dispatch('edit_online', { online: false })
		})
	},
	beforeDestroy() {
		this.manageEventListeners.destroy()
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.slide-enter-active, .slide-leave-active,
.slide-enter-active .bar, .slide-leave-active .bar
	transition 0.25s easeOutQuart
.slide-enter .bar, .slide-leave-to .bar
	transform translateY(-100%)

.ui-offline-bar
	position absolute
	left 0
	top 0
	right 0
	bottom 0
	// z-index 10
	.bar
		position absolute
		left 0
		top 0
		right 0
		height 48px
		padding 8px 0
		// z-index 6
		background #e53935 url(../../assets/img/offline-stripe.svg)
		box-shadow 0 1px 0px darken(#e53935, 25%)
		color #fff
		text-align center
		font-size 1.2rem
		white-space nowrap
		line-height 16px

</style>
