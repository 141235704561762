<template>
	<div class="intro">
		<div class="bg"></div>
		<transition name="notepad" appear>
			<notepad :pages="pages" :type="type" />
		</transition>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Notepad from '@/components/game/notepad'
import UiTextContent from '@/components/ui/ui-text-content'
import Vue from 'vue'

export default {
	name: 'intro',
	data() {
		return {

		}
	},
	props: {
		type: { default: 'signature' }
	},
	computed: {
		...mapGetters([ 'app', 'game' ]),
		pages() {
			let pages = []

			if (this.type === 'signature') {
				pages = (this.game?.current?.step?.contentTexts || [])
					.map(({ html:text }) => ({
						title: Vue.i18n.translate('disclaimer'),
						text
					}))
			} else {
				let texts = this.get_content_texts_by_tag('result_description')
				for (let i in texts) {
					pages.push({ title: "", text: texts[i].html })
				}
				texts = this.get_content_texts_by_tag('result_learn_more')
				for (let i in texts) {
					pages.push({ title: "", text: texts[i].html })
				}
			}
			return pages
		}
	},
	methods: {
		get_content_texts_by_tag(tag) {
			return this.game.current.step.contentTexts.filter((t) => t.tags.includes(tag))
		},
	},
	components: { UiTextContent, Notepad }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile
	.intro
		z-index 2

.intro
	position absolute
	left 0
	top 0
	right 0
	bottom 0
	display flex
	align-items center
	justify-content center
	// background-color alpha(dark, 80%)
	.bg
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		background-color alpha(dark, 80%)


</style>
