<template>
	<div class="research-papers-licences">
		<div class="bg"></div>
		<div class="scroll" v-scrollbar>
			<div>
				<div class="research-papers-licences-content">
					<p class="description" v-html="$t('research_papers_licences_description')"></p>

					<div class="licences">
						<licence v-for="licence in app.research_papers_licences" :licence="licence" :key="licence.id" />
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'
import Licence from '@/components/administrator-access/licence'

export default {
	name: 'research-papers-licences',
	data() {
		return {
			licences: []
		}
	},
	computed: {
		...mapGetters([ 'app' ])
	},
	methods: {
		async get_files() {
			const r = await Apollo.query('CLIENT_FILE_ACCESSIBLE')
			if (r && r.data) this.$store.commit('EDIT_APP_STATE', { research_papers_licences: r.data })
		}
	},
	async created() {
		this.get_files()
	},
	components: { Licence }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

#app.mobile
	.research-papers-licences
		.research-papers-licences-content
			padding 16px
		
.research-papers-licences
	// position absolute
	// right 32px
	// top 32px
	// height calc(100% - 92px)
	// width calc(100% - 64px)
	width 100%
	height 100%
	// background-color #FCFDFF
	// border 2px solid dark
	text-align left
	// border-top-left-radius 16px
	// border-top-right-radius 16px
	font-size 1.4rem
	transition 0.25s easeOutQuart
	transform-origin top right
	&.comments-enter, &.comments-leave-to
		transform scale(0)
		opacity 0
	.scroll
		height 100%
		width 100%
		.research-papers-licences-content
			padding 24px
			.description
				display block
				margin 0 0 24px 0
				color dark
				line-height 20px
				flex flex-start flex-start
			
			.licences
				.licence
					margin 0 0 24px 0
					background-color #484C57
					border-radius 16px
					padding 16px
					flex flex-start flex-start
					color #fff
					box-shadow 0px 5px 2px alpha(#000, 30%)
					.left
						width calc(100% - 220px)
						padding 0 16px 0 0
						.title
							flex flex-start center
							height 56px
							margin 0 0 8px 0
							font-size 2rem
							line-height 1em
							text-transform uppercase
							border-bottom 1px solid #ccc
						.author
							margin 0 0 8px 0
						.availability
							margin 0 0 16px 0
						.buttons
							.more-button
								display inline-block
								height 40px
								padding 0 40px 0 16px
								border 1px solid #ccc
								border-radius 8px
								line-height @height
								cursor pointer
								&:hover
									background-color alpha(#fff, 10%)
								.arrow
									position absolute
									right 16px
									top 14px
									width 0
									height 0
									border-style solid
									border-width 12px 8px 0 8px
									border-color #fff transparent transparent transparent


					.right
						width 220px
						.remaining
							height 56px
							margin 0 0 8px 0
							padding 0 16px
							flex flex-start center
							background-color #EFF3FE
							color #484C57
							.value
								margin 0 8px 0 0
								font-size 5rem
								line-height 1em
							.label
								font-size 1.8rem
								line-height 1.2em
								text-transform uppercase
						.add-new-licences
							background-color #5A5F6E
							text-align center
							padding 8px
							> .label
								margin 0 0 4px 0
								text-transform uppercase
							.input-number
								flex center center
								height 40px
								margin 0 0 8px 0
								.arrow
									width @height
									height @height
									flex center center
									cursor pointer
									user-select none
									&.prev:after
										content ''
										display inline-block
										width 0
										height 0
										border-style solid
										border-width 12px 16px 12px 0
										border-color transparent #fff transparent transparent
									&.next:after
										content ''
										display inline-block
										width 0
										height 0
										border-style solid
										border-width 12px 0 12px 16px
										border-color transparent transparent transparent #fff
								.input
									height @height
									width (64px)
									border none
									background none
									font inherit
									color inherit
									outline none
									-webkit-appearance textfield
									-moz-appearance textfield
									appearance textfield
									border-bottom 1px solid #ccc
									text-align center
									font-size 3rem
									line-height 1em
									&::-webkit-inner-spin-button, 
									&::-webkit-outer-spin-button
										-webkit-appearance none
										margin 0
									&:hover, &:focus
										background-color alpha(#fff, 10%)
										border-color #fff
							.button
								width calc(100% - 16px)
								margin 0 auto 8px auto
								background-color #7FAE51
								height 32px
								line-height @height
								padding 0 0 8px 0
								cursor pointer
								user-select none
								&:hover
									background-color darken(@background-color, 10%)
								&:active
									background-color darken(@background-color, 10%)
									transform translateY(2px)
							.unlimited-access
								flex flex-start center
								border-top 1px solid #777
								padding 8px 0 0 8px
								cursor pointer
								&:hover .checkbox
									background-color alpha(#fff, 10%)
								.checkbox
									width 24px
									height 24px
									margin 0 8px 0 0
									border 2px solid #fff
								.label
									text-transform uppercase

</style>
