<template>
	<div class="game-background" :class="{ blured: game.ui.active.modal || app.end_demo }" :style="{ width: bg_width + 'px', left: bg_left + 'px' }">
		<img :src="background_url" class="bg" ref="bg" @load="bg_loaded()" draggable="false">
		<div class="patient" :class="{ visible: lottie_character != false }" ref="patient"></div>
		<div v-if="game.current.step.layout == 'trivia'" class="patient-box" v-hammer:tap="()=> show_question()">
			<transition name="good-answer">
				<div v-if="is_best_answer" class="good-answer"></div>
			</transition>
		</div>

		<transition name="bubble">
			<div class="bubble"
				v-if="game.current.step.layout == 'trivia'"
				v-hammer:tap="()=> show_question()"
			>...</div>
		</transition>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import {manageLottie} from "@/js/managers/manageLottie"
import {manageEventListeners} from "@/js/managers/manageEventListeners"
import {garbageCollector} from "@/js/utils/garbageCollector"

export default {
	name: 'game-background',
	data() {
		return {
			lottie_character: false,
			bg_width: 0,
			bg_left: 0,
			is_best_answer: false,
			manageEventListeners: manageEventListeners()
		}
	},
	computed: {
		...mapGetters([ 'app', 'game' ]),
		background_url() {
			return this.$medias.find({ tags: [ 'background', 'room', this.app.day_moment ] })
		},
		character_url() {
			return this.$medias.get({ id: this.game.ui.character_animation.id })
		}
	},
	watch: {
		'game.current.step'(newValue, oldValue) {
			if (newValue && newValue.layout && newValue.layout == 'intro') {
				this.bg_loaded()
			}
		},
		'game.current.score'(newValue, oldValue) {
			if (newValue === 1) {
				this.is_best_answer = true
				setTimeout(() => {
					this.is_best_answer = false
				}, 1250)
			} else
				this.is_best_answer = false
		},
		'game.ui.active.modal'(newValue, oldValue) {
			if (this.lottie_character) {
				if (newValue) this.lottie_character.pause()
				else this.lottie_character.play()
			}
		},
		character_url(newValue, oldValue) {
			const ref = this.$refs.patient
			if (this.character_url) {
				let autoplay = (this.game.ui.active.modal == false)
				this.lottie_character =	manageLottie.create({
					container: ref,
					path: this.character_url,
					renderer: 'svg',
					loop: true,
					autoplay: autoplay,
				})
				if (this.lottie_character) {
					this.manageEventListeners.add(this.lottie_character, 'DOMLoaded', () => {
						if (ref.childNodes.length > 1) ref.removeChild(ref.childNodes[0])
						this.resize()
					})
				}
			} else {
				ref.removeChild(ref.childNodes[0])
				if (this.lottie_character.destroy) this.lottie_character.destroy()
				this.lottie_character = false
			}
		},
	},
	methods: {
		bg_loaded() {
			this.resize()
		},
		show_question() {
			this.$store.dispatch('show_current_question')
		},
		resize() {
			if (this.$refs.bg) {
				setTimeout(() => {
					this.bg_width = this.$refs.bg.clientWidth
					this.bg_left = (window.innerWidth - this.$refs.bg.clientWidth) / 2
					this.$emit('loaded')
				}, 100)
			}
		},
	},
	mounted() {
		this.manageEventListeners.add(window, 'resize', this.resize)
	},
	beforeDestroy() {
		this.manageEventListeners.destroy()
		setTimeout(() => {
			garbageCollector.destroy()
		}, 1000)
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.perf-normal, #app.perf-high
	.bubble-enter-active, .bubble-leave-active
		transition 0.25s easeOutQuart
	.bubble-enter
		transform translateY(-40px)
		opacity 0
	.bubble-leave-to
		opacity 0
	.game-background
		.patient
			transition 0.5s easeOutQuart


#app.perf-low
	.game-background.blured
		filter blur(0)


.game-background
	position absolute
	left 0
	right 0
	bottom 0
	height 100%
	background-color #000
	transition filter 0.25s easeOutQuart
	&.blured
		filter blur(12px)
	.bg
		position absolute
		left 50%
		bottom 0
		height 100%
		transform translateX(-50%)
	.patient, .patient-box
		position absolute
		top 30%
		left 43%
		width 20%
		height 62%
	.patient
		opacity 0
		transform translateX(40px)
		transform-origin bottom center
		// &:before
		// 	content ''
		// 	position absolute
		// 	left 0
		// 	top 0
		// 	right 0
		// 	bottom 0
		// 	background-color alpha(red, 50%)
		&.visible
			opacity 1
			transform translateX(0)
	.patient-box
		cursor pointer
		.good-answer
			position absolute
			right 24px
			top 24px
			width 40px
			height 40px
			background url(../../assets/img/star-good-answer.svg) center center no-repeat
			background-size 100% auto
			transform scale(2) rotate(-5deg)
			&.good-answer-enter-active
				transition 0.5s easeInQuart
			&.good-answer-leave-active
				transition 0.5s easeOutQuart
			&.good-answer-enter
				transform scale(18) rotate(40deg)
				opacity 0
			&.good-answer-leave-to
				transform scale(0.5) rotate(0) translateY(-150px)
				opacity 0
	.bubble
		position absolute
		left calc(50% - (98px / 2))
		top calc(25% - 28px)
		width 98px
		height 56px
		// transform translate(-50%, -50%)
		background url(../../assets/img/bubble.svg) center center no-repeat
		background-size auto 100%
		cursor pointer
		line-height (@height - 24px)
		text-align center
		font-size 3rem
</style>
