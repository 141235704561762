<template>
	<div class="end-demo">
		<div class="bg"></div>
		<div class="content">
			<div class="main-icon">
				<svg id="successAnimation" class="animated" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
					<path id="successAnimationResult" fill="#D8D8D8" d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"/>
					<circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" stroke-width="2" stroke-linecap="round" fill="transparent"/>
					<polyline id="successAnimationCheck" stroke="#979797" stroke-width="2" points="23 34 34 43 47 27" fill="transparent"/>
				</svg>
			</div>
			<div class="text-content">
				<h1 class="title">Thank you for playing!</h1>
				<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Provident, sequi iste quaerat ipsa reiciendis harum facere, dolorum quis accusantium esse.</p>
				<p>Maiores in dicta ipsum voluptas totam dignissimos architecto.</p>
				<div class="buttons">
					<ui-button v-hammer:tap="()=> send_feedback()">Send feedback</ui-button>
					<ui-button v-hammer:tap="()=> go_home()" color="red">Go to home</ui-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'end-demo',
	data() {
		return {
			animated: false
		}
	},
	computed: {
		...mapGetters([ 'game' ]),
	},
	methods: {
		send_feedback() {
			window.location.href = "mailto:jamie@affordancestudio.com?subject=Feedback%20Level%20Up%20MD&body=Write%20here%20some%20comments%20about%20Level%20Up%20MD"
		},
		go_home() {
			this.$store.dispatch('init')
		}
	},
	mounted() {
		setTimeout(() => {
			this.animated = true
		}, 1000)
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'



#app.perf-normal, #app.perf-high
	.end-demo-enter-active,
	.end-demo-leave-active,
	.end-demo-enter-active .content,
	.end-demo-leave-active .content
		transition 0.25s easeOutQuart

	.end-demo-enter, .end-demo-leave-to
		opacity 0

	.end-demo-enter .content
		transform translateY(-50px)
		opacity 0
	.end-demo-leave-to .content
		transform translateY(-50px)
		opacity 0


#app.mobile
	.end-demo
		z-index 2

.end-demo
	position absolute
	left 0
	right 0
	bottom 0
	top 0
	display flex
	align-items center
	justify-content center
	.bg
		position absolute
		left 0
		right 0
		bottom 0
		top 0
		background-color alpha(#000, 85%)
	.content
		background-color #fff
		width calc(100% - 32px)
		max-width 450px
		margin-top 80px
		box-shadow 0 4px 8px alpha(dark, 50%)
		padding 32px
		border-radius 16px
		.main-icon
			position absolute
			left 50%
			top 0
			width 120px
			height 120px
			transform translate(-50%, -50%)
			background-color #fff
			// box-shadow 0 0 0 4px #fff
			border-radius 50%
			> svg
				position absolute
				left calc(50% - 70px)
				top calc(50% - 70px)
				width 140px
				height 140px
		.text-content
			padding-top 40px
			.title
				display block
				margin 0 0 16px 0
				font-size 2.2rem
				font-weight 700
				line-height 30px
			p
				display block
				margin 0 0 16px 0
				line-height 20px
			.buttons
				padding-top 16px
				display flex
				align-items center
				justify-content center
				flex-wrap wrap
				.ui-button
					margin 0 8px 8px 0


circle_length = 151px
check_length = 36px
green = #8BC34A

@keyframes scaleAnimation
	0%
		opacity 0
		transform scale(1.5)
	100%
		opacity 1
		transform scale(1)

@keyframes drawCircle
	0%
		stroke-dashoffset circle_length
	100%
		stroke-dashoffset 0

@keyframes drawCheck
	0%
		stroke-dashoffset check_length
	100%
		stroke-dashoffset 0

@keyframes fadeOut
	0%
		opacity 1
	100%
		opacity 0

@keyframes fadeIn
	0%
		opacity 0
	100%
		opacity 1

#successAnimationCircle
	stroke-dasharray circle_length circle_length
	stroke green

#successAnimationCheck
	stroke-dasharray check_length check_length
	stroke green

#successAnimationResult
	fill green
	opacity 0

#successAnimation.animated
	animation 1s ease-out 0s 1 both scaleAnimation
	#successAnimationCircle
		animation 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut
	#successAnimationCheck
		animation 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut
	#successAnimationResult
		animation 0.3s linear 0.9s both fadeIn








</style>
