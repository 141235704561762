<template>
	<div class="page-content-result">
		<div class="page-title" v-html="get_text('result_title')"></div>

		<div class="page-scroll" v-scrollbar:scrollArrows>
			<div>
				<ui-text-content :html="get_text('result_description')" />
				<br><br>
				<ui-text-content :html="get_text('result_learn_more')" />
			</div>
		</div>

		<div class="footer">
			<div class="footer-title">{{ $t('clinic_progress') }}</div>
			<div class="footer-content">
				<div class="progress-bar">
					<div class="progress" :style="progressBar({ user })"></div>
					<div class="star"><span>{{ level({ user }) }}</span></div>
					<div class="infos">{{ unitsInLevel({ user }) }} / {{ unitsNeeded({ user }) }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiTextContent from '@/components/ui/ui-text-content'
import { mixinUser } from '@/components/mixins/user'

export default {
	name: 'page-result',
	mixins: [ mixinUser ],
	data() {
		return {

		}
	},
	computed: {
		...mapGetters([ 'game' ]),
	},
	methods: {
		get_text(slug) {
			let t = ''
			let texts = this.game.current.step.contentTexts.filter((t) => t.tags.includes(slug))
			for (var i in texts) {
				if (texts[i].html) t += texts[i].html
			}
			if (t) return t
			return ''
		},
	},
	components: { UiTextContent }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/variables'


.page-content-result
	height 100%

	.page-scroll
		height calc(100% - 168px)

	.footer
		height 120px
		border-top 2px solid blue
		.footer-title
			height 40px
			color blue
			text-align center
			white-space nowrap
			text-transform uppercase
			line-height @height
			font-weight 700
			font-size 2rem
		.progress-bar
			height 16px
			margin 16px auto 0 auto
			width calc(100% - 32px)
			background-color yellow
			border-radius 16px
			.progress
				width 50%
				height 100%
				background-color red
				border-top-left-radius 16px
				border-bottom-left-radius 16px
			.star
				position absolute
				left -16px
				top calc(50% - 36px)
				padding-left 3px
				width 64px
				height 64px
				color #fff
				text-align center
				line-height 70px
				font-size 2.4rem
				&:before
					content ''
					position absolute
					left 0
					top 0
					width 100%
					height 100%
					background url(../../../../assets/img/star-red.svg) center center no-repeat
					background-size 100% auto
					transform rotate(-12deg)
			.infos
				position absolute
				right 0
				bottom -24px
				height 24px
				color red
				line-height @height
		.input
			display block
			width 100%
			height 100%
			margin 16px 0
			height 40px
			padding 0 16px
			border 1px solid #c65555
			border-radius 24px
			background transparent url(../../../../assets/img/send-red.svg) right 8px top 3px no-repeat
			background-size 28px 28px
			resize none
			font inherit
			color dark_light
			line-height (@height - 2px)



</style>
