<template>
	<div class="game">
		<div class="game-content" ref="game_content">
			<game-background ref="background" @loaded="set_loaded" />

			<transition name="patient-report" appear>
				<patient-report v-if="game.ui.patient_report" v-show="game.ui.active.patient_report_visible || app.device == 'desktop'" />
			</transition>

			<transition name="answers-selector">
				<answers-selector v-if="game.current.step.layout == 'trivia'" v-show="!game.ui.active.patient_report_visible" />
			</transition>

			<transition name="game-result">
				<game-result v-if="game.current.step.layout == 'result' && game.current.completed && !app.end_demo" v-show="!game.ui.active.patient_report_visible" />
			</transition>

			<transition name="intro">
				<intro v-if="game.current.step.layout == 'result' && !game.current.completed" type="buttons" />
			</transition>
		</div>

		<bottom-bar />

		<transition name="intro">
			<intro v-if="game.current.step.layout == 'intro'" />
		</transition>

		<transition name="dialog-box">
			<dialog-box v-if="game.ui.active.modal" :modal="game.ui.active.modal" />
		</transition>

		<transition name="learn-more">
			<learn-more v-if="game.ui.active.learn_more" :learn_more="game.ui.active.learn_more" />
		</transition>

		<transition name="end-demo">
			<end-demo v-if="app.end_demo" />
		</transition>

		<div v-if="!game.current.completed" class="home-button" v-hammer:tap="()=> home()"></div>




		<div v-if="!game.tuto.done && game.current.step.layout != 'feedback' && !(game.current.step.layout != 'trivia' && game.tuto.current_step == 6)" class="tuto-background"></div>

		<!-- TUTO CLONES -->
		<patient-report v-if="!game.tuto.done && game.tuto.current_step >= 3 && game.tuto.current_step <= 5 && game.current.step.layout == 'presentation'" :tuto="true" />
		<bottom-bar v-if="!game.tuto.done && game.tuto.current_step >= 5 && game.current.step.layout != 'feedback'" :tuto="true" />
		<answers-selector v-if="!game.tuto.done && game.tuto.current_step == 6 && game.current.step.layout == 'trivia'" :tuto="true" />
		<!-- END TUTO CLONES -->

		<transition name="tuto-notification" appear>
			<tuto-notification v-if="tuto_visible" :tuto_step="current_tuto_step" />
		</transition>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import GameBackground from '@/components/game/game-background'
import PatientReport from '@/components/game/patient-report'
import DialogBox from '@/components/game/dialog-box'
import Intro from '@/components/game/intro'
import LearnMore from '@/components/game/learn-more'
import AnswersSelector from '@/components/game/answers-selector'
import BottomBar from '@/components/game/bottom-bar'
import TutoNotification from '@/components/game/tuto-notification'
import GameResult from '@/components/game/game-result'
import EndDemo from '@/components/game/end-demo'
import {manageEventListeners} from "@/js/managers/manageEventListeners"

export default {
	name: 'game',
	data() {
		return {
			ratio: 0,
			manageEventListeners: manageEventListeners()
		}
	},
	computed: {
		...mapGetters([ 'app', 'game' ]),
		current_tuto_step() {
			return this.game.tuto.steps[this.game.tuto.current_step-1]
		},
		tuto_visible() {
			return !this.game.tuto.done && this.current_tuto_step && this.current_tuto_step.params.step == this.game.current.step.layout && !this.game.ui.active.modal
		}
	},
	methods: {
		resize() {
			if (this.$refs.game_content) {
				let r = this.$refs.game_content.clientWidth / this.$refs.game_content.clientHeight
				if (r > 1.8)
					this.$refs.background.$el.style.height = Math.round(window.innerWidth / 1.8) + 'px'
				else
					this.$refs.background.$el.style.height = '100%'
			}
		},
		async home() {
			await this.$store.dispatch('init')
		},
		set_loaded() {
			this.$store.dispatch('set_main_loader', { main_loader: false })
			this.$emit('loaded')
		}
	},
	mounted() {
		this.manageEventListeners.add(window, 'resize', this.resize)
		this.resize()
	},
	components: { GameBackground, PatientReport, DialogBox, Intro, LearnMore, AnswersSelector, BottomBar, TutoNotification, GameResult, EndDemo },
	beforeDestroy() {
		this.manageEventListeners.destroy()
	}
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'



#app.desktop, #app.tablet
	.game
		.home-button
			width 72px
			height 72px
			background-size 32px 32px

.game
	position absolute
	left 0
	top 0
	right 0
	bottom 0
	height 100%
	min-width 320px
	overflow hidden
	margin 0 auto
	will-change transform
	.game-content
		height calc(100% - 64px)
		// perspective 1000px
	.home-button
		position absolute
		right 0
		top 0
		width 56px
		height 56px
		background dark url(../assets/img/home.svg) right 30% top 30% no-repeat
		background-size 24px 24px
		border-bottom-left-radius 100%
		cursor pointer
		z-index 2

	.tuto-background
		position absolute
		right 0
		top 0
		left 0
		bottom 0
		background-color alpha(dark, 80%)





</style>
