<template>
	<div class="answer-text" :class="[ color ]">
		<div class="text" v-scrollbar="{ maxHeight: 355, backgroundColor }">
			<ui-text-content :html="answer.contentTexts[0].html" />
			<div class="buttons"><ui-button v-hammer:tap="()=> select_answer()" color="white" :class="{ selected: selected }">
				<span v-if="selected">{{ $t('unselect') }}</span>
				<span v-else>{{ $t('select') }}</span>
			</ui-button></div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiTextContent from '@/components/ui/ui-text-content'

export default {
	name: 'answer-text',
	data() {
		return {

		}
	},
	props: [ 'answer', 'color' ],
	computed: {
		...mapGetters([ 'game' ]),
		backgroundColor() {
			return this.$props.color
		},
		selected() {
			return this.game.ui.active.selected_answers.findIndex((a) => { return a.id === this.answer.id }) > -1
		}
	},
	methods: {
		select_answer() {
			this.$store.dispatch('toggle_selected_answer', { answer: this.answer })
			this.$store.dispatch('set_active_answer', { answer: false })
			// if (this.tuto && !this.game.tuto.done) this.$store.dispatch('next_tuto_step')
		}
	},
	components: { UiTextContent }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.answer-text
	position absolute
	left 50%
	transform translateX(-50%)
	width 85%
	bottom (64px + 64px)
	padding 8px 8px 8px 16px
	color #fff
	&.blue
		background-color alpha(blue, 100%)
		box-shadow 0 0 0 4px blue_light
		.buttons
			>>> .ui-button .button
				color blue
	&.red
		background-color alpha(red, 100%)
		box-shadow 0 0 0 4px red_light
		.buttons
			>>> .ui-button .button
				color red
	&.yellow
		background-color alpha(yellow, 100%)
		box-shadow 0 0 0 4px yellow_light
		.buttons
			>>> .ui-button .button
				color yellow
	&.purple
		background-color alpha(purple, 100%)
		box-shadow 0 0 0 4px purple_light
		.buttons
			>>> .ui-button .button
				color purple
	&.darkblue
		background-color alpha(darkblue, 100%)
		box-shadow 0 0 0 4px darkblue_light
		.buttons
			>>> .ui-button .button
				color darkblue
	&.mandy
		background-color alpha(mandy, 100%)
		box-shadow 0 0 0 4px mandy_light
		.buttons
			>>> .ui-button .button
				color mandy
	>>> .ui-text-content a
		color #fff
		text-decoration underline
		&:hover
			text-decoration none
	.buttons
		margin 16px 0 8px 0
		text-align center
		// font-weight 700
		>>> .ui-button
			// box-shadow 0 0 0 4px alpha(#000, 15%)
			box-shadow 0 2px 2px 0 alpha(#000, 35%)
			text-transform uppercase
			font-size 1.6rem
			&.selected .button
				background-color #E96161
				color #fff
				box-shadow 0 0 0 2px #fff, 0 2px 2px 0 alpha(#000, 35%)
			.button
				padding-top 2px

</style>
